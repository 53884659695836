import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Archivos } from 'src/app/enviar-denuncia/models/formData.model';
import { Documento } from 'src/app/enviar-denuncia/models/documento';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css'],
  animations: []
})
export class ArchivosComponent implements OnInit {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  title = 'texto_358';
  lastModified: string = "0";
  //list to add files of type Archivos
  archivosList: Archivos[] = [];
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', []),
    descripcion: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
  });
  //List to manage the files before adding them to ArchivoList
  files: any = [];
  documento: Documento[];
  sizeList: number = 0;
  sizeListMb: number = 0;


  constructor(
    private translate: TranslateService,
    private router: Router,
    private formDataService: FormDataService,
    private auth: AuthService,
    private toastr: ToastrService,) {
  }

  ngOnInit() {
    //get the list of files by pressing goToPrevious or goToNext
    this.archivosList = this.formDataService.getArchivos();
    let totalSizeBytes = 0;
    //add the files size
    this.archivosList.forEach(archivo => {
      totalSizeBytes += archivo.nombreArchivo.size;
    });
    this.sizeListMb = totalSizeBytes / 1000000;

  }

  //Add the selected file to files
  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];

      this.files.push(element.name)
      if (this.files.length > 5) {
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      } else {
        if (element.size / 1000000 > 16) {
          this.files.pop(element.name)
          alert(this.translate.instant('texto_333'));
        } else {
          this.lastModified = element.lastModified
          this.onFileChange(event);
        }
      }
    }
  }

  //Add files to archivosList and set them to setArchivos
  submit() {
    this.files = [];

    // Assign data from the form to Archivos
    const archivo: Archivos = {
      nombreArchivo: this.myForm.get('fileSource').value,
      descripcionArchivo: this.myForm.get('descripcion').value
    };

    // Verify that a file exists by its size
    if (archivo.nombreArchivo.size > 0) {
      const newFileSizeMb = (this.sizeList + archivo.nombreArchivo.size) / 1000000; // Calculate new total size in MB

      if (newFileSizeMb < 20) {
        // Add archivo to archivosList
        this.archivosList.push(archivo);
        // Update total size
        this.sizeList += archivo.nombreArchivo.size;
        this.sizeListMb = this.sizeList / 1000000;

        // Clean up the form to allow adding a new file and its description
        this.myForm.reset();
        // Save archivosList
        this.formDataService.setArchivos(this.archivosList);
      } else {
        // Alert when exceeding the maximum size
        this.toastr.error(this.translate.instant('texto_333'));
      }
    }
  }

  // Delete a file and its description from archivosList
  deleteItemArchivosList(index) {
    // subtract the size of the deleted file from the total file size
    this.sizeList -= this.archivosList[index].nombreArchivo.size;
    this.sizeListMb = this.sizeList / 1000000; // Recalcular el tamaño total en MB

    // delete file from archivosList
    this.archivosList.splice(index, 1);
    this.formDataService.setArchivos(this.archivosList);
  }


  //delete a file from the Files list
  deleteItemFiles(index) {
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;
    if (this.myForm.get('fileSource').value == "") {
      formData.append('delete_file', this.documento[0].n_archivo);
    }
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    return true;
  }

  goToPrevious(form: any) {
    if (this.save(form)) {
      this.router.navigate(['/enviar-denuncia/testigo']);
    }
  }

  goToNext(form: any) {

    if (this.save(form)) {
      this.router.navigate(['/enviar-denuncia/finalizar-denuncia']);

    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  onFileChangeDrag(event) {
    if (event.length > 0) {
      const file = event[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  get descripcion() {
    return this.myForm.get('descripcion');
  }

  hide() {
    this.auth.logoutSimple();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  dragFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name)
      if (this.files.length > 1) {
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      } else {
        if (element.size / 1000000 > 16) {
          this.files.pop(element.name)
          alert(this.translate.instant('texto_333'));
        } else {
          this.lastModified = element.lastModified
          this.onFileChangeDrag(event);
        }

      }
    }
  }



}
