
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>ID. {{ 'texto_156' | translate }}</th>
                            <th>N. {{ 'texto_156' | translate }}</th>
                            <th>{{ 'texto_95' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of cautelares">
                            <td>{{ item.id_mcu }}</td>
                            <td>{{ item.nombre }}</td>
                            <td><div [innerHtml]="item.comentario | safe: 'html'"></div></td>
                            <td class="td-actions text-right" style="width: 90px;">
                              <button (click)="showCaution(item)" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button> 

                              <button (click)="deleteCaution(item.id_mcu)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>   


                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"   class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                          <!--Content-->                      
                          <div class="modal-content" >
                            <div class="modal-body mx-3">
                              <div class="card-header card-header-info">
                                <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_157' | translate }}</h5>
                            </div>
                            
                            <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()">X</button> 

                              <div class="md-form mb-5">
                                <input type="text"  class="form-control" [formControl]="signupFormModalId"
                                       mdbInput mdbValidate value={{idMedida}} readonly>
                                <label for="orangeForm-email">ID. {{ 'texto_156' | translate }}</label>
                                <mdb-error
                                  *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success
                                  *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>
                      
                              <div class="md-form mb-5">
                                <input type="text"  class="form-control" [formControl]="signupFormModalName"
                                       mdbInput mdbValidate maxlength="255" value={{nombreCautelar}}>
                                <label for="orangeForm-name">N. {{ 'texto_156' | translate }}</label>
                                <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>
                  
                              <div class="md-form">
                                  <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                  <textarea maxlength="4000" type="text" id="formMedidaModal" class="md-textarea form-control" rows="5" mdbInput [formControl]="contactFormModalMessage" value={{comentario}}></textarea>
                                  <label for="formMedidaModal">{{ 'texto_46' | translate }}</label>
                                </div>
                            </div>
                  
                          
                          <div class="text-center mt-4">
                            <button (click)="updateCaution(idMedida)"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  <app-add-medida-cautelar></app-add-medida-cautelar>

                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
