import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { Usuarios } from '../dashboard/models/usuarios';
import { environment } from "../../environments/environment"
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { DatePipe } from '@angular/common';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-asignar-decisor',
  templateUrl: './asignar-decisor.component.html',
  styleUrls: ['./asignar-decisor.component.css'],
  providers:[DatePipe]
})
export class AsignarDecisorComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  decisores: Usuarios[];
  plazos: PlazosDenuncia[];
  roles: Rol[];

  constructor(private apiService: ApiService,private actRoute: ActivatedRoute, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      selectDecisor: new FormControl('', [Validators.required])
    });
    let url = window.location.href.split("/", 6);
    let idDenuncia = url[5];

    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(),"3",false,"nulo").subscribe((usuarios: Usuarios[])=>{
      this.decisores = usuarios;
    });
  }

  get selectDecisor() {
    return this.validatingForm.get('selectDecisor');
  }

  asignarDecisor(){

    this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "4", this.auth.getSociedad(),"","").subscribe(
      res => {
        if(res == 200){
         /* this.apiService.createHistoryComplaintById(this.denuncia[0].id_denuncia,"4",this.auth.getSociedad(),this.selectDecisor.value).subscribe(
            res => {
              if(res == 200){*/
                this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
                  res => {
                    if(res == 200){
                      this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.selectDecisor.value, this.denuncia[0].id_denuncia,"1", '1', '3', false).subscribe(
                        res => {
                          if(res == 200){

                            let $clau = false;
                            let clausulas_correo = localStorage.getItem("clausulas_correo");
                            if(clausulas_correo == '1'){
                              $clau = true;
                            }

                            this.toastr.success(this.translate.instant('texto_296'), this.translate.instant('texto_297'));
                            this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[])=>{
                              this.plazos = investigator;
                              let fecha = new Date(); // ó new Date(valor);
                              let plazo = this.plazos[0].plazo_decisor;
                              fecha.setDate(fecha.getDate() + parseInt(plazo));
                              let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                              let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                              let message =this.translate.instant('texto_576') + " "  +  this.selectDecisor.value  + this.translate.instant('texto_592') + " "  + this.denuncia[0].id_denuncia + " "  + this.translate.instant('texto_593') + " " +_date;
                              this.apiService.sendEmail(this.plazos[0].mail_user,this.translate.instant('texto_564')+this.denuncia[0].id_denuncia, message,url,$clau, this.translate.instant('texto_595')).subscribe(
                                res => {
                                  if(res == 200){
                                  //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                    //let role = this.auth.getRole();
                                    let role = "";
                                    this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
                                      this.roles = roles;
                                      for(let i=0;i<this.roles.length;i++){
                                        role = role + "," +this.roles[i].n_rol;
                                      }
                                    if(role.includes("DECISOR") && this.plazos[0].id_user == this.auth.getIdUser()){
                                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                                      });
                                    }else{
                                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                                      });
                                    }
                                  });
                                  }else{
                                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }
                              });
                            });

                            if(this.denuncia[0].denunciante_correo != ""){
                              this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[])=>{
                                this.plazos = investigator;
                                let fecha = new Date(); // ó new Date(valor);
                                let plazo = this.plazos[0].plazo_decisor;
                                fecha.setDate(fecha.getDate() + parseInt(plazo));
                                let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                                let message = this.translate.instant('texto_576') + " "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_596')  + " " + this.denuncia[0].id_denuncia  + " " + this.translate.instant('texto_597') + " " +_date;
                                this.apiService.sendEmail(this.denuncia[0].denunciante_correo,this.translate.instant('texto_598') , message,url,$clau, this.translate.instant('texto_598') ).subscribe(
                                res => {
                                  if(res == 200){
                                  //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                  }else{
                                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }
                                });
                              });
                            }

                          }else{
                            this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                          }
                        });
                    }else{
                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    }
                });

          /*    }else{
                this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
              }
            });*/
        }else{
           this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
        });
    });
  }

}
