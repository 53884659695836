      <!-- Content Area -->
      <div class="tab-content">
        <form #receptorForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <div class='row' [hidden]="inicial">
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <div class='col-xs-12 col-sm-12'>
                    <div class="form-group">
                      <!--<div style="text-align: center;display: relative;" id="receptor" name="receptor"  [ngDefaultControl] [(ngModel)]="receptor.receptor">{{ 'texto_148' | translate }} TEXTO MANTENIMIENTO <b>{{receptores[indexA].n_user}}  {{receptores[indexA].ape_user}}</b></div> <br> -->
                      <div style="text-align: center;display: relative;"
                        id="receptor" name="receptor"
                        [(ngModel)]="receptor.receptor">{{ 'texto_148' |
                        translate }} <b>{{receptores[indexA]?.n_user}}
                          {{receptores[indexA]?.ape_user}}</b></div> <br>
                      <div *ngIf="gestionar_conflicto =='1'">
                        <small
                          style="font-size: 12px;font-weight: bold;font-style: italic;">*{{
                          'texto_149' | translate }}</small><br>
                        <button class="btn btn-outline-rounded btn-default"
                          (click)="buscarReceptores()">{{ 'texto_150' |
                          translate }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <button class="btn btn-outline-rounded btn-default"
                    (click)="goToPrevious(receptorForm)"> <span
                      style="margin-right:10px;"
                      class="glyphicon glyphicon-arrow-left"></span> {{
                    'texto_142' | translate }}</button>
                  <button class="btn btn-success btn-outline-rounded btn-info"
                    [disabled]="!receptorForm.valid"
                    (click)="goToNext(receptorForm)"> {{ 'texto_141' | translate
                    }} <span style="margin-left:10px;"
                      class="glyphicon glyphicon-arrow-right"></span></button>
                </div>

                <div class="comentario">
                  <div>
                    <span
                      style="font-size: 12px;display:block;line-height: normal;">{{
                      'texto_659'| translate }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class='row' [hidden]="!inicial">
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <div class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <mat-form-field style="min-width:300px;">
                        <mat-label>{{ 'texto_35' | translate }}</mat-label>
                        <mat-select id="receptor" name="receptor"
                          [(ngModel)]="receptor.receptor">
                          <mat-option *ngFor="let recept of receptores"
                            [value]="recept?.id_user">
                            {{recept?.n_user}} {{recept?.ape_user}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class='col-xs-12 col-sm-12'>
                    <label class="control-label" for="motivo">{{ 'texto_63' |
                      translate }}</label>
                    <textarea (keyup)="onKey($event)" maxlength="4000"
                      class="form-control input-md" #motivo="ngModel"
                      id="motivo" name="motivo" type="text"
                      placeholder="{{ 'texto_63' | translate }}"
                      [(ngModel)]="receptor.motivo" rows="8"
                      ></textarea>
                    <span
                      style="float: right;font-weight: bold;font-size:12px">{{contador}}
                      {{ 'texto_69' | translate }} 4000</span>
                    <button class="btn btn-outline-rounded btn-default"
                      (click)="deshacer()">{{ 'texto_151' | translate
                      }}</button>
                  </div>
                </div>
                <div class="form-group text-center" style="margin-top: 30px;">
                  <button class="btn btn-outline-rounded btn-default"
                    (click)="goToPrevious(receptorForm)"> <span
                      style="margin-right:10px;"
                      class="glyphicon glyphicon-arrow-left"></span> {{
                    'texto_142' | translate }}</button>
                  <button class="btn btn-success btn-outline-rounded btn-info"
                    [disabled]="!receptorForm.valid"
                    (click)="goToNext(receptorForm)"> {{ 'texto_141' | translate
                    }} <span style="margin-left:10px;"
                      class="glyphicon glyphicon-arrow-right"></span></button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>