import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { Rol } from 'src/app/model/rol';
import { OptionSesion } from 'src/app/model/optionSesion';
import { ToastrService } from 'ngx-toastr';
import { SessionTimerService } from 'session-expiration-alert';
import { Parametros } from 'src/app/model/parametros';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  color:string = "black";
  option: OptionSesion[];
  isVisible = true;
  IsAuth = false;
  roles: Rol[];
  hasPermit: boolean = false;
  hasPermitDenuncia: boolean = false;
  hasPermitSADM: boolean = false;
  hasPermitAllDenuncias: boolean = false;
  seeMenu1: boolean = false;
  seeMenu2: boolean = true;
  seeMenu3: boolean = false;
  @Input() menuNavbar: boolean;
  @Input() IsHiddenLanding: boolean;
  parametros: Parametros;
  bloque1: boolean = false;
  bloque2: boolean = false;
  bloque3: boolean = false;
  isAgrupado = false;

  mostrar: Boolean = false; //variable que sirve para mostrar el menú Centros en HTML según su valor true OR false

  esSADM: string = "0";
  archivardenunciaF: boolean = false;
  clientes: String;

  constructor(private router: Router,private auth: AuthService,private apiService: ApiService, private translate: TranslateService, private toastr: ToastrService, public sessionTimer: SessionTimerService) { }


  ngOnInit() {
  /*  this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    this.userIdle.onTimeout().subscribe(() =>
    {
      this.onTimeout();
      console.log('Time is up!');
    });*/

    //Se obtiene el valor del centro (Si está activado = true)
    let centers = this.auth.getCentros();

    //Valora el valor del centro (Funciona para que no se pierda el valor del Observable al momento de recargar la página)
    if(centers == '1'){
      this.auth.openSidebar(true);
    } else if(centers == '0') {
      this.auth.openSidebar(false);
    } else if(this.mostrar == true){
      this.auth.openSidebar(true);
    } else {
      this.auth.openSidebar(false);
    }

    //Observable para activar el menú de centros en la barra izquierda  1
    this.auth.sidebarOpened$
    .pipe()
    .subscribe((res: boolean) => (this.mostrar = res));

    localStorage.setItem('esSADM', "0");

    this.IsAuth = this.auth.isLoggedIn();
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    if(this.IsAuth){
      this.IsHiddenLanding = false;
      this.apiService.searchAdvancedParam2(this.auth.getSociedad()).subscribe((parametros: Parametros)=>{
        this.parametros = parametros;
        if(this.parametros[0].valor == "1"){
          this.auth.setAnonimo("1");
        }else{
          this.auth.setAnonimo("");
        }
        this.auth.setRemitente(this.parametros[1].valor);
        this.auth.setConsultas(this.parametros[2].valor);
        this.auth.setAviso(this.parametros[3].valor);
        this.auth.setArchdenuncia(this.parametros[4].valor);
        if(this.auth.getCentros() == '1'){
          this.auth.openSidebar(true);
        } else {
          this.auth.openSidebar(false);
        }
      });
    }else{
      this.IsHiddenLanding = true;
    }
    this.translate.setDefaultLang(language);
    if(this.auth.getIdUser()==null){
      this.isVisible = false;
    }else{
      let role = "";
      if(this.auth.getSociedad() == null){
        this.apiService.getSessionPropertiesUser().subscribe((option: OptionSesion[])=>{
          this.option = option;
          if(option[0].id_sociedad != ""){
            this.auth.setLanguage(option[0].idioma);
            this.auth.setSociedad(option[0].id_sociedad);
            this.auth.setNameSociedad(option[0].n_sociedad);

            //Get role user with from backend

            this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
              this.roles = roles;
              for(let i=0;i<this.roles.length;i++){
                role = role + "," +this.roles[i].n_rol;
              }

              //role = this.roles[0].n_rol;
              if(role != ""){
                //this.auth.setRole(role);
                if(role.includes("ADMIN")  || role.includes("SUPERADMIN")){
                  this.hasPermit = true;
                  this.bloque1 = true;
                  this.bloque2 = true;
                  this.hasPermitSADM = false;
                //Setea valor al centers para mostrar el menú de centros
                if(this.auth.getCentros() == '1'){
                  this.auth.openSidebar(true);
                }else {
                  this.auth.openSidebar(false);
                }

                 // this.hasPermitAllDenuncias = true;
                  if(role.includes("SUPERADMIN")){
                    this.bloque1 = false;
                    this.bloque3 = true;
                    this.hasPermitDenuncia = false;
                    this.hasPermitSADM = true;
                    localStorage.setItem('esSADM', "1");
                  }
                  //Setea valor al centers para mostrar el menú de centros
                  if(this.auth.getCentros() == '1'){
                    this.auth.openSidebar(true);
                  }else {
                    this.auth.openSidebar(false);
                  }
                }
                 if(role.includes("DECISOR")  || role.includes("RECEPTOR")  || role.includes("GESTOR")  || role.includes("INVESTIGADOR")  || role.includes("COMPLIANCE") ){
                  this.hasPermitDenuncia = true;
                  this.bloque1 = false;
                  this.hasPermitSADM = false;
                  if(role.includes("COMPLIANCE")){
                    this.hasPermitAllDenuncias = true;
                    this.bloque3 = true;
                  }
                }
              }
            });
          }else{
            this.toastr.error(this.translate.instant('texto_242'), this.translate.instant('texto_243"'));
          }
        });
      }else{
        this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
          this.roles = roles;
          for(let i=0;i<this.roles.length;i++){
            role = role + "," +this.roles[i].n_rol;
          }
          //role = this.roles[0].n_rol;
          if(role != ""){
            //this.auth.setRole(role);
            if(role.includes("ADMIN")  || role.includes("SUPERADMIN")){
              this.hasPermit = true;
              this.bloque1 = true;
              this.bloque2 = true;
              //this.bloque3 = true;
              this.hasPermitSADM = false;
                //Setea valor al centers para mostrar el menú de centros
                if(this.auth.getCentros() == '1'){
                  this.auth.openSidebar(true);
                }else {
                  this.auth.openSidebar(false);
                }

              //this.hasPermitAllDenuncias = true;
              if(role.includes("SUPERADMIN")){
                this.bloque1 = false;
                this.hasPermitDenuncia = false;
                this.hasPermitSADM = true;
                this.bloque3 = true;
                localStorage.setItem('esSADM', "1");

                //Setea valor al centers para mostrar el menú de centros
                if(this.auth.getCentros() == '1'){
                  this.auth.openSidebar(true);
                }else {
                  this.auth.openSidebar(false);
                }
              }
            }
             if(role.includes("DECISOR")  || role.includes("RECEPTOR")  || role.includes("GESTOR") || role.includes("INVESTIGADOR")  || role.includes("COMPLIANCE") ){
              this.hasPermitDenuncia = true;
              this.bloque1 = false;
              this.hasPermitSADM = false;
              if(role.includes("COMPLIANCE")){
                this.hasPermitAllDenuncias = true;
                this.bloque3 = true;
              }
            }
          }
        });
      }
    }
  }


  logout(){
    //console.log(localStorage.getItem("forzarloSSO"));
    if (localStorage.getItem("forzarloSSO") == "1"){
      this.apiService.logoutSingleSignOn(localStorage.getItem("session_index"),localStorage.getItem("nameid_value"),localStorage.getItem("nameid_format"));
    }else{
      this.auth.logoutSimple();
      window.location.reload();
    }
  }

  showMenu(){
    this.isAgrupado = !this.isAgrupado;
    document.getElementById("main-panel").style.zIndex = "999";
    if(this.isAgrupado){
      document.getElementById("main-panel").style.width = "calc(100% - 60px)";
      for(var i=0; i< document.getElementsByClassName('nav-link').length; i++){
        var div = document.getElementsByClassName('nav-link')[i]
        div.setAttribute("style","margin-left:0px");
      }

      setTimeout(function(){ document.getElementById("btnAgrupado").style.left = "10px"; }, 150);
    }else{
      document.getElementById("main-panel").style.width = "calc(100% - 260px)";
      for(var i=0; i< document.getElementsByClassName('nav-link').length; i++){
        var div = document.getElementsByClassName('nav-link')[i]
        div.setAttribute("style","margin-left: 14px;");
      }
      setTimeout(function(){ document.getElementById("btnAgrupado").style.left = "200px"; }, 150);

    }
    //this.isVisible =  !this.isVisible;
  }

  hidePanel(){
    if(this.isVisible){
      this.isVisible =  false;
    }
  }

  changeMenu1(){
    this.seeMenu1 = !this.seeMenu1;
  }

  changeMenu2(){
    this.seeMenu2 = !this.seeMenu2;
  }

  changeMenu3(){
    this.seeMenu3 = !this.seeMenu3;
  }
}
