import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-sociedad',
  templateUrl: './add-sociedad.component.html',
  styleUrls: ['./add-sociedad.component.css']
})
export class AddSociedadComponent implements OnInit {
  validatingForm: FormGroup;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalWeb: new FormControl('', Validators.required),
      signupFormModalId: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalWeb() {
    return this.validatingForm.get('signupFormModalWeb');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }


  addSociedad(){
    this.apiService.addSociedad(this.signupFormModalId.value, this.signupFormModalName.value, this.signupFormModalWeb.value, this.contactFormModalMessage.value).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_569'), this.translate.instant('texto_572'));
        }else{
          this.toastr.error(this.translate.instant('texto_571'), this.translate.instant('texto_572'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['sociedades']);
        });
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_571'), this.translate.instant('texto_572'));
    });
  }

  hideModal(){
    this.IsHiddenForm = false;
  }
}
