<section style="position: absolute;width: 100%;z-index: 1;"
  class="image-container"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;"
        class="btn btn-dark" (click)="hide();">X</button>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{
              'texto_717'
              | translate }}</li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->

      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #tipoForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h4 class="head text-center"> {{ title | translate }}</h4>
            <div class='row'>
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <!--SELECTED EMPRESA WITH SEARCH INPUT-->
                  <div *ngIf="showInputAutocomplete" class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="tipoHecho">{{ 'texto_67'
                        | translate }}</label> <br>
                      <mat-form-field [color]="colorfieldAutocomplete">
                        <input matInput #miInput id="orangeForm-name"
                          [formControl]="searchInput"
                          placeholder="Buscar..." [matAutocomplete]="auto"
                          (input)="onInputChange(miInput.value)">

                        <mat-autocomplete #auto="matAutocomplete">
                          <ng-container
                            *ngIf="suggestions && suggestions.length > 0; else noSuggestions">
                            <mat-option *ngFor="let suggestion of suggestions"
                              (click)="selectSuggestion(suggestion.n_empresa, suggestion.id_empresa, suggestion.id_sociedad)">{{
                              suggestion.n_empresa
                              }}
                            </mat-option>
                          </ng-container>
                          <!-- Mensaje de error cuando no hay sugerencias -->
                          <ng-template #noSuggestions>
                            <mat-option>
                              {{ 'texto_713' | translate }}
                            </mat-option>
                          </ng-template>
                        </mat-autocomplete>
                      </mat-form-field>
                      <!-- <div *ngIf="hideerrorentrycompanies" class="alert alert-danger" style="font-size:12px;">
                        No existe ninguna empresa con este nombre
                      </div> -->

                    </div>
                  </div>
                  <!--SELECTED EMPRESA-->
                  <div *ngIf="!showInputAutocomplete"
                    class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="tipoHecho">{{ 'texto_67'
                        | translate }}</label> <br>
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #empresa="ngModel" id="empresa"
                          name="empresa" (selectionChange)="onChange($event)"
                          [(ngModel)]="selectedCompany"
                          (click)="onClick($event)" required>
                          <mat-option *ngFor="let emp of empresas"
                            [value]="emp">
                            {{emp.n_empresa}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--SELECTED CENTER-->
                  <div class='col-xs-12 col-sm-6' *ngIf=showSelected;>
                    <div class="form-group">
                      <label class="control-label" for="tipoHecho">{{
                        'texto_672' | translate }}</label> <br>
                      <!--<label class="control-label" for="tipoHecho">Centros</label> <br>-->
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #empresa="ngModel" id="centro" name="centro"
                          (selectionChange)="onChangeCenter($event)"
                          [(ngModel)]="selectedCenter" required>
                          <mat-option *ngFor="let cent of centros"
                            [value]="cent">
                            {{cent.n_centro}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--SELECTED TIPO DENUNCIA-->
                  <div class='col-xs-12 col-sm-6'>
                    <div class="form-group">
                      <label class="control-label" for="tipoHecho">{{ 'texto_71'
                        | translate }}</label> <br>
                      <mat-form-field>
                        <mat-label>{{ 'texto_140' | translate }}</mat-label>
                        <mat-select #tipoDenuncia="ngModel" id="tipoDenuncia"
                          name="tipoDenuncia"
                          [(ngModel)]="tipoHecho.tipoDenuncia" required
                          (click)="onClickComplaint($event)" required
                          (selectionChange)="onChangeComplaint($event)">
                          <mat-option matTooltip="{{denuncia.nombre}}"
                            *ngFor="let denuncia of denuncias"
                            [value]="denuncia.id_tp_denuncia">
                            {{denuncia.nombre}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!--DESCRIPCIÓN TIPO DE DENUNCIA-->
                      <div style="font-size: 15px;line-height: 16px;"
                        [innerHtml]="descripcion | safe: 'html'"></div>
                    </div>
                  </div>

                  <!--RECEPTOR-->
                  <div class="col-xs-12 col-sm-12">
                    <div class='col-xs-12 col-sm-6'
                      style="padding-left: 0px; padding-right: 0px;"
                      *ngIf="ShowSetReceptor">
                      <div class="form-group">
                        <label class="control-label" for>{{ 'texto_148' |
                          translate }}</label> <br>
                        <p id="receptor" value
                          name="receptor">{{Receptoraleatorio}}</p>
                      </div>
                    </div>

                    <div *ngIf="HiddeIsNotConflict">
                      <div class='col-xs-12 col-sm-6' style="padding-left: 0px; padding-right: 0px;" *ngIf="Showselect">
                        <div class="form-group">
                          <label class="control-label" for="tipoHecho">{{
                            'texto_491' | translate }}</label> <br>
                          <mat-form-field>
                            <mat-label>{{ 'texto_35' | translate }}</mat-label>
                            <mat-select id="receptor" name="receptor"
                              [(ngModel)]="receptor"
                              (selectionChange)="onChangeReceptor($event)"
                              (click)="onClickReceptor($event)" required>
                              <mat-option *ngFor="let recept of receptores"
                                [value]="recept.id_user">
                                {{recept.n_user}} {{recept.ape_user}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class='col-xs-12 col-sm-6' *ngIf="ShowSetReceptor">
                        <div class="form-group">
                          <label class="control-label" for>{{ 'texto_155' |
                            translate }}</label> <br>
                          <button style="color:#fff" mdbBtn rounded="true"
                            class="btn-receptor"
                            (click)="searchReceptors()">{{ 'texto_150' |
                            translate }}
                          </button>
                        </div>
                      </div>
                      <div class='col-xs-12 col-sm-6' *ngIf="typeButton">
                        <div class="form-group">
                          <label class="control-label" for>{{ 'texto_155' |
                            translate }}</label> <br>
                          <button (click)="automaticReceptors()"
                            style="color:#fff" mdbBtn rounded="true"
                            class="btn-receptor" mdbWavesEffect>{{ 'texto_694' |
                            translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END RECEPTOR-->
                </div>

                <div class="form-group text-center" style="margin-top: 30px;">
                  <button (click)="goToNext(tipoForm)" style="color:#fff" mdbBtn
                    rounded="true" class="btn-next"
                    mdbWavesEffect>{{ 'texto_141' | translate }} <span
                      style="margin-left:10px;"
                      class="glyphicon glyphicon-arrow-right"> </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div #recapthca></div>
        </form>
      </div>
    </div>
  </div>
</section>
