
<button style="float:right;" class="btn btn-primary " type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>{{ 'texto_60' | translate }}
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content">
        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <!-- AÑADIR EMPRESA -->
              <!-- <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_65' | translate }}</h5> -->
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_673' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button>

          <!--INPUT ID CENTRO-->
            <div class="md-form mb-5">
              <input type="text" id="orangeForm-email" class="form-control" [formControl]="signupFormModalId"
                     mdbInput mdbValidate>
              <!-- <label for="orangeForm-email">ID. {{ 'texto_67' | translate }}</label> -->
              <label for="orangeForm-email">ID. {{ 'texto_672' | translate }}</label>
              <mdb-error
                *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success
                *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
              </mdb-success>
            </div>
            <!--INPUT NOMBRE CENTRO-->
            <div class="md-form mb-5">
              <input type="text" id="orangeForm-name" class="form-control" [formControl]="signupFormModalName"
                     mdbInput mdbValidate maxlength="255">
              <!-- <label for="orangeForm-name">{{ 'texto_64' | translate }}</label> -->
              <label for="orangeForm-name">{{ 'texto_675' | translate }}</label>
              <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                {{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                {{ 'texto_62' | translate }}
              </mdb-success>
            </div>
            <!--INPUT EMPRESAS-->
            <div class="md-form mb-5">
              <!-- <label for="orangeForm-empresa">ID. {{ 'texto_67' | translate }}</label><br> -->
              <label for="orangeForm-empresa">ID. {{ 'texto_67' | translate }}</label><br>
              <mat-form-field>
                  <mat-select [formControl]="signupFormModalidEmpresa">
                      <mat-option *ngFor="let empresa of empresas" [value]="empresa.id_empresa">
                      {{empresa.n_empresa}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mdb-error *ngIf="signupFormModalidEmpresa.invalid && (signupFormModalidEmpresa.dirty || signupFormModalidEmpresa.touched)">
                {{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success *ngIf="signupFormModalidEmpresa.valid && (signupFormModalidEmpresa.dirty || signupFormModalidEmpresa.touched)">
                {{ 'texto_62' | translate }}
              </mdb-success>
            <br>
            </div>
            <!--INPUT COMENTARIOS-->
            <div class="md-form">
              <label for="form-email">{{ 'texto_46' | translate }}</label>
              <mdb-icon fas icon="pencil-alt" class="prefix grey-text" style="margin-top: 10%;"></mdb-icon>
                <textarea maxlength="4000" type="text" id="form107" class="md-textarea form-control" rows="5" mdbInput [formControl]="contactFormModalMessage"></textarea>
              </div>

          </div>

        <div class="text-center mt-4">
          <button (click)="addCenter()"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>
