<section style="position: absolute;width: 100%;z-index: 1;"
  class="image-container"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;"
        class="btn btn-dark" (click)="hide();">X</button>
      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{
              'texto_717'
              | translate }}</li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->
      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #datosDenunciaForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h4 class="head text-center">{{title | translate }}</h4>
            <div class='row'>
              <div class="grid-container">
                <div class="fecha">
                  <div class="form-group">
                    <label class="control-label" for="receptor">{{ 'texto_345'|
                      translate }}</label> <br>
                    <mat-form-field>
                      <input matInput required
                        [matDatepicker]="fechaDatosDenuncia" [max]="hoy"
                        placeholder="{{ 'texto_92'| translate }}"
                        id="fechaDatosDenuncia" name="fechaDatosDenuncia"
                        [(ngModel)]="datos.fechaDatosDenuncia">
                      <mat-datepicker-toggle matSuffix
                        [for]="fechaDatosDenuncia"></mat-datepicker-toggle>
                      <mat-datepicker #fechaDatosDenuncia></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="comentario">
                  <div>
                    <span
                      style="font-size: 12px;display:block;line-height: normal;">{{
                      'texto_346'| translate }}</span>
                  </div>
                </div>
                <div class="descripcion">
                  <div style="position: relative;">
                    <label class="control-label"
                      for="descripcionDatosDenuncia">{{ 'texto_63' | translate
                      }}</label>
                    <textarea
                      [(ngModel)]="datos.descripcionDatosDenuncia"
                      (ngModelChange)="onDescriptionChange($event)"
                      (keyup)="onKey($event)" maxlength="4000" required
                      class="form-control input-md"
                      #descripcionDatosDenuncia="ngModel"
                      id="descripcionDatosDenuncia"
                      name="descripcionDatosDenuncia" type="text"
                      placeholder="{{ 'texto_63' | translate }}"
                      rows="8"></textarea>
                    <span style="position: absolute; bottom: 5px; right: 5px;">
                      <span (click)="showRecorder(true)"
                        style="cursor: pointer;"
                        class="btn-record"
                        matTooltip="{{ 'texto_692' | translate }}">
                        <mat-icon>mic</mat-icon>
                      </span>
                    </span>
                    <span
                      style="float: left; font-weight: bold; font-size: 12px;">
                      {{contador}} {{ 'texto_69' | translate }} 4000
                    </span>
                  </div>
                </div>

                <div class="botones">
                  <div class="form-group text-center">
                    <button (click)="goToPrevious(datosDenunciaForm)"
                      style="color:#fff" mdbBtn rounded="true"
                      class="btn-back" mdbWavesEffect><span
                        style="margin-right:10px;"
                        class="glyphicon glyphicon-arrow-left">
                      </span>{{ 'texto_142' | translate }}
                    </button>

                    <button [disabled]="!datosDenunciaForm.valid || isNotCompleteInformationAudio"
                    (click)="goToNext(datosDenunciaForm)"
                    style="color:#fff" mdbBtn rounded="true" class="btn-next"
                    mdbWavesEffect>{{ 'texto_141' | translate }}
              <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span>
            </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!--MODAL AUDIO RECORDING-->
<div class="ui" *ngIf="showModalAudioRecording">
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="wrapper" style="width: 100%;">
    <div class="recorder">
      <button type="button" style="z-index:100;float: right;"
        class="btn btn-dark"
        (click)="showRecorder(false)">X</button>

      <div class="container d-flex justify-content-center align-items-center">
        <form #miFormulario="ngForm">
          <mat-form-field style="width: 140px;">
            <mat-select placeholder="Selecciona un idioma"
              [(value)]="idiomaSeleccionado"
              (selectionChange)="onIdiomaSeleccionadoChange()">
              <mat-option *ngFor="let idioma of idiomas"
                [value]="idioma.value">{{ idioma.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="recorder2">

        <div class="record-btn " *ngIf="!showButtonRec" role="button"
          (click)="startService()">

          <i class="material-icons icon"
            [innerHTML]="isAudioRecording ? 'stop' : 'mic_outline'"></i>
        </div>
        <div class="record-btn play-btn" [class.recording]="isAudioRecording"
          *ngIf="showButtonRec" (click)="stop()">
          <span name="stop" class="material-icons">stop</span>
        </div>
        <div class="timer" id="audioTimer" *ngIf="isAudioRecording">{{
          audioRecordedTime }}</div>
        <div class="player">
          <div style="font-size: 12px;line-height: 16px;"
            [innerHtml]="'*El audio se transcribirá al finalizar la grabación.'| safe: 'html'">
          </div>
          <div style="font-size: 12px;line-height: 16px; padding-top: 6px;"
            [innerHtml]="'*Se recomienda grabar sin ruido en el entorno.'| safe: 'html'">
          </div>
        </div>
        <div class *ngIf="isStoppedSpeechRecog" style="padding-top: 10px;">
          <button (click)="clearAudioRecordedData()" style="color:#fff" mdbBtn
            rounded="true" class="btn-back"
            mdbWavesEffect>{{ 'texto_151' | translate }}
          </button>
          <button (click)="showRecorder(false)" style="color:#fff" mdbBtn
            rounded="true" class="btn-next"
            mdbWavesEffect>
            {{ 'texto_141' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
