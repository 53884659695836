<button style="background-image: url(/../../assets/img/botones/Boton_Asignar.png);" class="btn btn-finish  btn-wd btn-cabecera" type="button" matTooltip="{{'texto_104' | translate }}" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content" >

        <div class="modal-body mx-3">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title" style="color:white">{{ 'texto_104' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 

          <div class="row">
            <div class="md-form mb-5 col-md-12">
                <input type="text" id="form-name" class="form-control" [formControl]="signupFormModalName"
                mdbInput mdbValidate>
                <label for="form-name">{{ 'texto_35' | translate }}</label>
                <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                  {{ 'texto_61' | translate }}
                </mdb-error>
                <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                  {{ 'texto_62' | translate }}
                </mdb-success>
            </div>


            <div class="md-form mb-5 col-md-12">
              <input type="text" id="form-email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" class="form-control" [formControl]="signupFormModalEmail"
                     mdbInput >
              <label for="form-email">Email</label>
              <div *ngIf="signupFormModalEmail.invalid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)" class="alert alert-danger">
                <div style="font-size:12px">
                    {{ 'texto_106' | translate }}
                </div>                                            
              </div>
            </div>

            <div class="md-form mb-5 col-md-12">
                <input type="text" id="form-tel" class="form-control" [formControl]="signupFormModalTelefono" maxlength="25" 
                       mdbInput >
                <label for="form-tel">{{ 'texto_66' | translate }}</label>
                <div *ngIf="signupFormModalTelefono.invalid  && (signupFormModalTelefono.dirty || signupFormModalTelefono.touched)"  class="alert alert-danger">
                  <div style="font-size:12px">
                      {{ 'texto_105' | translate }}
                  </div>
              </div>
              </div>
        </div>
              

            <div class="md-form mb-5 col-md-12">
                <label for="form-email">{{ 'texto_46' | translate }}</label>
              <mdb-icon fas icon="pencil-alt" class="prefix grey-text" style="margin-top: 10%;"></mdb-icon>
              <textarea type="text" maxlength="4000" id="form107" class="md-textarea form-control" rows="4" mdbInput [formControl]="contactFormModalMessage"></textarea>
            </div>
    
          </div>

        <div class="text-center mt-4">
          <button (click)="addWitness()" mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>