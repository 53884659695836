export class FormData {
    empresa: string = '';
    transcripcion: string = '';
    idCentro: string = '';
    identificadorCentro: string ='';
    idEmpresa: string = '';
    identificadorEmpresa: string = '';
    tipoDenuncia: string = '';
    firstName: string = '';
    lastName : string = '';
    email: string = '';
    phone: string = '';
    description: string = '';
    denuncianteRelacion: string = '';
    denuncianteFirstName: string = '';
    denuncianteLastName : string = '';
    denuncianteEmail: string = '';
    denunciantePhone: string = '';
    //denuncianteDNI: string = '';
    work: string = '';
    street: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    relacion: string = '';
    receptor: string = '';
    motivo: string = '';
    receptorInicial: string = '';
    motivoInicial: string = '';
    fechaDatosDenuncia: string = '';
    descripcionDatosDenuncia: string = '';
    nombre: string = '';
    comentario: string = '';
    terminos: string = '';
    proteccionDatos: string = '';
    descripcion: string = '';
    nombreArchivo: File;
    descripcionArchivo: string = '';
    id_texto: string = '';
    texto: string = '';
    idioma: string = '';
    modificable: string = '';
    id_clausula: string = '';
    clausula: string = '';
    boton1: string = '';
    boton2: string = '';
    boton3: string = '';
    sociedad: string = '';
    testigos: Personal[] = []; 

    clear() {
        this.sociedad ='';
        this.empresa = '';
        this.idCentro = '';
        this.identificadorCentro ='';
        this.idEmpresa = '';
        this.identificadorEmpresa = '';
        this.tipoDenuncia = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
        this.description = '';
        this.denuncianteRelacion = '';
        this.denuncianteFirstName = '';
        this.denuncianteLastName = '';
        this.denuncianteEmail = '';
        this.denunciantePhone = '';
        //this.denuncianteDNI = '';
        this.work = '';
        this.street = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.relacion = '';
        this.receptor = '';
        this.motivo = '';
        this.receptorInicial = '';
        this.motivoInicial = '';
        this.fechaDatosDenuncia = '';
        this.transcripcion = '';
        this.descripcionDatosDenuncia = '';
        this.nombre = '';
        this.comentario = '';
        this.terminos = '';
        this.proteccionDatos = '';
        this.descripcion = '';
        //
        this.nombreArchivo;
        this.descripcionArchivo = '';
        this.id_texto = '';
        this.texto = '';
        this.idioma = '';
        this.modificable = '';

    }
}

export class Personal {
    firstName: string = '';
    lastName : string = '';
    email: string = '';
    phone: string = '';
    description: string = '';

}

export class Address {
    street: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
}

export class TipoHecho {
    empresa: string = '';
    tipoDenuncia: string = '';
    idEmpresa: string = '';
    identificadorEmpresa: string = '';
    idCentro: string = '';
    identificadorCentro: string ='';

}

export class EmpresaHecho {
    idEmpresa: string = '';
}

export class Textos {
    id_texto: string = '';
    texto: string = '';
    idioma: string = '';
    modificable: string = '';
}

export class Clausulas {
    id_clausula: string = '';
    clausula: string = '';
}

export class Datos {
    fechaDatosDenuncia: string = '';
    descripcionDatosDenuncia: string = '';
}

export class DatosDenunciante {
    denuncianteRelacion: string = '';
    denuncianteFirstName: string = '';
    denuncianteLastName : string = '';
    denuncianteEmail: string = '';
    denunciantePhone: string = '';
    //denuncianteDNI: string = '';
}

export class Receptor {
    receptor: string = '';
    motivo: string = '';
}

export class ReceptorInicial {
    receptorInicial: string = '';
    motivoInicial: string = '';
}

// export class Archivos {
//         nombreArchivo: File;
//         descripcionArchivo: string;

// }

export class Archivos {
    nombreArchivo: File;
    descripcionArchivo: string;
}


export class FinalizarDenuncia {
    comentario: string = '';
    terminos: string = '';
    proteccionDatos: string='';
}

export class GrabarDenuncia {
    idEmpresa: string = '';
    idCentro: string = '';
    tipoDenuncia: string='';
    relacion: string='';
    receptor: string='';
    receptorInicial: string = '';
    sociedad: string='';
    denuncianteFirstName: string = '';
    denuncianteLastName : string = '';
    denuncianteEmail: string = '';
    denunciantePhone: string = '';
    fechaDatosDenuncia: string = '';
    terminos: string = '';
    transcripcion: string = '';

}
