<footer class="bg-secondary da-section">
    <div class="container text-white">  
        <div class="col-md-12">
            <p style="font-size:12px; text-align: center;">&copy; 2024 Becompliance. Todos los derechos reservados.</p>
        </div>
        <!-- <div class="col-md-6">
            <p style="font-size:10px;">Política de Cookies</p>
            <a routerLink="/politica-cookies" routerLinkActive="active">Política de Cookies</a>
        </div> -->
    </div>
</footer>