
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>ID. {{ 'texto_101' | translate }}</th>
                            <th>N. {{ 'texto_101' | translate }}</th>
                            <th>{{ 'texto_95' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of relaciones">
                            <td>{{ item.id_tp_relacion }}</td>
                            <td>{{ item.nombre }}</td>
                            <td><div [innerHtml]="item.comentario | safe: 'html'"></div></td>
                        
                            <td class="td-actions text-right" style="width: 90px;"> 
                              <button (click)="showRelation(item)" type="button" rel="tooltip" title="Edit Type" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button>               
                              <button (click)="deleteRelationType(item.id_tp_relacion)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>


                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"  style="width: 400px !important;margin: 0 auto;margin:auto" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm" style="max-width: 400px;" role="document">
                          <!--Content-->                      
                          <div class="modal-content" style="width: 400px !important;">
                            <div class="modal-body mx-3">
                              <div class="card-header card-header-info">
                                <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_138' | translate }}</h5>
                            </div>

                            <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()">X</button> 


                            <div class="row">
                              <div class="md-form mb-5 col-md-12">
                                <input type="text" class="form-control" [formControl]="signupFormModalId"
                                       mdbInput mdbValidate value={{idRelacion}} readonly>
                                <label for="orangeForm-email">ID. {{ 'texto_101' | translate }}</label>
                                <mdb-error
                                  *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success
                                  *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>
                      
                              <div class="md-form mb-5  col-md-12">
                                <input type="text" class="form-control" [formControl]="signupFormModalName"
                                       mdbInput mdbValidate maxlength="255" value={{nombre}}>
                                <label for="orangeForm-name">N. {{ 'texto_101' | translate }}</label>
                                <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>
                            </div>
                              <div class="md-form">
                                <label for="form-email">{{ 'texto_46' | translate }}</label>
                                <mdb-icon fas icon="pencil-alt" class="prefix grey-text" style="margin-top: 10%;"></mdb-icon>
                                  <textarea maxlength="4000" placeholder="{{ 'texto_63' | translate }}" type="text" mdbInput [formControl]="contactFormModalMessage" [(ngModel)]="comentario" class="md-textarea form-control" rows="5" value={{comentario}}></textarea>
                                </div>
                            </div>
                  
                          
                          <div class="text-center mt-4">
                            <button (click)="updateRelation(idRelacion)"  mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                            </button>
                          </div>
                  
                        </div>
                      </div>
                    </div>
    
                  
                  <app-add-tipo-relacion></app-add-tipo-relacion>
              </div>
            </div>
      </div>
    </div>
  </div>
