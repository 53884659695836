

<div class="contenedor" style="padding: 0px; display: flex;">
  <a class="nav-link smooth-scroll" style="color: #000000!important; text-transform: uppercase; padding-top: 0px !important;" (click)="showMenu()">
    {{ 'texto_153' | translate }}
  </a>
  <mat-select #menu class="select" [(value)]="activeLang" (selectionChange)="cambiarLenguaje($event.value)">
    <mat-option *ngFor="let idioma of idiomas" [value]="idioma.id_idioma">
      <span style="display: flex; align-items: center;">
        <img src="/assets/img/idiomas/{{ idioma.bandera }}" style="width: 30px; height: 20px; margin-right: 10px;">
        {{ idioma.n_idioma }}
      </span>
    </mat-option>
  </mat-select>
</div>

