import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
   providedIn: 'root'
})
export class SpinnerService {
 private isLoaddingSubject = new BehaviorSubject<boolean>(false);
 subject$ = this.isLoaddingSubject.asObservable();

 isLoadding$ = new Subject<boolean>(); //observable

 show(): void {
    this.isLoadding$.next(true);
 }

 hide(): void {
    this.isLoadding$.next(false);
 }

 sendSignal(): void {
   this.isLoaddingSubject.next(true);
 }


}