<p>prueba-correo works!</p>
<button class="btn btn-primary btn-round" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>{{ 'texto_460' | translate }}
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [hidden]="!condition">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content">
      <div class="modal-body mx-3">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title" style="color:white">{{ 'texto_460' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()">X</button> 
        <form [formGroup]="validatingFormMail" style="margin-left: 25px; margin-right: 25px;">
          <div class="row"  style="padding-top:20px" >														                        
            <div class="col-md-12">
              <div class="form-group">
                <label class="bmd-label-floating">{{ 'texto_82' | translate }}</label>
                <input  id="email" type="email" class="form-control" >
              </div>
            </div>																												
          </div>
          <button (click)="enviarEmailPrueba()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_461' | translate }}</button>
          <div class="clearfix"></div>  
        </form>        
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>