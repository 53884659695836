 <div class style="z-index:90 !important;background-color:white;">
  <nav
    style="z-index:90 !important;padding-left: 5% !important;padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important; "
    class="navbar navbar-expand-lg navbar-dark bg-transparent px-0">
    <a href="#inicio" class="button-link">
    <img src="assets/img/logos/logo.png"
      width="160px" style="margin-left: -10px;">
    </a>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link smooth-scroll" style="color: #000000!important;"><app-translation></app-translation></a>
        </li>
      </ul>
  </nav>
</div>
  <section>
    <div class="close">
      <a href="/inicio" class="close-btn"><i
          class="bi bi-x-square-fill"></i></a>
    </div>
    <div class="breadcrum-div">
      <nav aria-label="breadcrumb" class="nav">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
              translate }}</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ 'texto_252'
            | translate }}</li>
        </ol>
      </nav>
    </div>

    <div class="container">
      <div class="left-side">
        <div class="col-md-10 col-sm-12 px-0 mx-0">
          <h3 class="h5 mt-3"
            style="color:var(--color-bg-seccion1);font-weight: bold;">{{
            'texto_5'
            | translate }}
          </h3>
          <h2 class="display-3"
            style="color:var(--color-bg-seccion1);font-weight: bold;">{{
            'texto_98'
            | translate
            }}</h2>
        </div>

      </div>
      <div class="right-side">
        <div class="form-container">
          <img class="logo" src="assets/img/logos/logo.png" >
          <!-- <form action="#" method="post">
                  <input type="email" id="email" name="email" placeholder="Email" required>
                  <input type="password" id="password" name="password" placeholder="Password" required>
                  <button type="submit">Login</button>
              </form> -->
          <div class="modal-body mx-3">
            <div class="md-form mb-5">
              <mdb-icon fas icon="envelope" class="prefix grey-text"
                style="display: contents;"></mdb-icon>
              <input type="email" id="defaultForm-email"
                [formControl]="loginFormModalEmail" class="form-control"
                mdbInput mdbValidate>
              <label for="defaultForm-email">Email</label>
              <mdb-error style="margin-left: 80%;top:-12px !important;"
                *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
                {{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success style="margin-left: 80%;top:-12px !important;"
                *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">{{
                'texto_62' | translate }}
              </mdb-success>
            </div>

            <div class="md-form mb-4">
              <mdb-icon fas icon="lock" class="prefix grey-text"
                style="display: contents;"></mdb-icon>
              <input type="password" id="defaultForm-pass"
                [formControl]="loginFormModalPassword" class="form-control"
                mdbInput mdbValidate>
              <label for="defaultForm-pass">Password</label>
              <mdb-error style="margin-left: 80%;top:-12px !important;"
                *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                {{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success style="margin-left: 80%;top:-12px !important;"
                *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                {{ 'texto_62' | translate }}
              </mdb-success>
              <div [hidden]="!loginOk" class="alert alert-danger" role="alert"
                style="text-align: center !important;">
                <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_522' |
                  translate }}</span>
              </div>
              <div [hidden]="!loginOkBlock" class="alert alert-danger"
                role="alert" style="text-align: center !important;">
                <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_523' |
                  translate }}</span>
              </div>
              <div [hidden]="!loginNoUser" class="alert alert-danger"
                role="alert"
                style="text-align: center !important;">
                <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_524' |
                  translate }}</span>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button (click)="login()"
                class="btn"
                mdbWavesEffect>{{ 'texto_252' | translate }}</button>
            </div>
            <div [hidden]="!HabilitarSSO">
              <div class="modal-footer d-flex justify-content-center">
                <button (click)="login2()"
                  class="btn" mdbWavesEffect>LOGIN CON SINGLE SING-ON</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </section>

