import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { Idiomas } from '../model/idiomas';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {
  @ViewChild('menu') menu!: MatSelect;

  public activeLang = 'es';
  idiomas: Idiomas[];
  constructor( private auth: AuthService,private apiService: ApiService,private translate: TranslateService) {
    this.translate.setDefaultLang(this.activeLang);
  }
  ngOnInit() {
    this.cargarIdiomasDesdeBD();


  }
  public cambiarLenguaje(lang) {
    this.auth.setLanguage(lang);
    this.activeLang = lang;
    this.translate.use(lang);
  }

  showMenu() {
    this.menu.open();
  }

  cargarIdiomasDesdeBD() {
    this.apiService.searchIdiomas().subscribe((idiomas: Idiomas[]) => {

      const ordenIdiomas = {
        'es': 1,
        'en': 2,
        'fr': 3,
        'pt': 4,
        'it': 5,
        'ct': 6,
        'ek': 7,

      };

      idiomas.sort((a, b) => ordenIdiomas[a.id_idioma] - ordenIdiomas[b.id_idioma]);
      this.idiomas = idiomas;
    });
  }


}
