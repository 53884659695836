import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Users } from '../../../model/users';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  users:  Users;
  email: string;
  password: string;
  HabilitarSSO: boolean = false;
  loginOk: boolean = false;
  loginOkBlock: boolean = false;
  loginNoUser: boolean = false;

  //sended2FactorEmail: boolean = false;

  @ViewChild("myModalInfo", {static: false}) myModalInfo: TemplateRef<any>;


  constructor(
    private modalService: NgbModal,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  validatingForm: FormGroup;
  ngOnInit() {
    // TODO: No veo implementado nada de single sign on
    if (localStorage.getItem("HabSSO") == "1") {
      this.HabilitarSSO = true;
    }else{
      this.HabilitarSSO = false;
    }
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      loginFormModalPassword: new FormControl('', [Validators.required])
    });
  }
  // mostrarLogin(){
  //   this.modalService.open(this.myModalInfo);
  // }

  // logar(){
  //   //console.log(localStorage.getItem("forzarSSO"));
  //   if (localStorage.getItem("forzarSSO") == "1"){
  //     this.login2();
  //   }  else{
  //     this.mostrarLogin();
  //   }
  // }
  // actualizarHabilitarSSO(valor: boolean) {
  //   this.HabilitarSSO = valor;
  // }
  login() {
    console.log("Valor de HabilitarSSO: LOGIN", this.HabilitarSSO);

    this.loginService.login(this.loginFormModalEmail.value, this.loginFormModalPassword.value)

      // .pipe(
      //   tap(response => {
      //     this.auth.setToken(response.access_token);
      //     this.auth.setRefreshToken(response.refreshToken);
      //   }),
      //   switchMap(loggedIn => {
      //     return this.apiService.meInfo();
      //   })
      // )
      .subscribe({
        next: () => {
          //this.sended2FactorEmail = true;
          this.toastr.info(
            this.translate.instant('texto_715'),
            this.translate.instant('texto_194'),{
              timeOut: 0,  // 0 para que no se cierre automáticamente
              closeButton: true,  // Puedes agregar un botón de cierre si lo deseas
          });
          // this.auth.sendToken(this.auth.getToken() ,userData.id_user,userData.n_user,userData.ape_user,userData.mail_user,userData.foto);
          // this.router.navigate(["admin"]);
          // location.reload();
          console.log("Valor de HabilitarSSO: SUBSCRIBE", this.HabilitarSSO);
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          if (errorResponse.status === 401) {
            if (errorResponse.error.remainingAttempts !== undefined) {
              this.showInvalidPasswordError();
            } else {
              this.showNotUserError();
            }
          }
          if (errorResponse.status === 423) {
            this.showUserBlockedError();
          }
        }
      });
    // this.apiService.login(this.loginFormModalEmail.value, this.loginFormModalPassword.value).subscribe(
    //   res => {
    //     console.log(res);
    //     if(res[0]){
    //       console.log("ENTRO"+res[0]);
    //       this.auth.sendToken(res[0]["jwtToken"],res[0]["id_user"],res[0]["n_user"],res[0]["ape_user"],res[0]["mail_user"],res[0]["foto"]);
    //       this.router.navigate(["admin"]);
    //       location.reload();
    //       this.loginOk = false;
    //     }else{
    //       this.loginOk = true;
    //     }
    //     console.log(this.loginOk);
    // },
    // (err:any) =>{
    //   console.log(err);
    // });
  }

  private showNotUserError() {
    this.loginOk = true;
    this.loginNoUser = false;
    this.loginOkBlock = false;
  }

  private showInvalidPasswordError() {
    this.loginOk = true;
    this.loginNoUser = false;
    this.loginOkBlock = false;
  }

  private showUserBlockedError() {
    this.loginOk = false;
    this.loginNoUser = false;
    this.loginOkBlock = true;
  }

  login2(){
    console.log("Invocado login2(). Valor de HabilitarSSO:", this.HabilitarSSO);
    this.loginService.loginSingleSignOn();
  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }


}
