<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-primary" style="border-color: #0c55a0;">
          <div class="panel-heading" style="display: block;">
            <h4 class="panel-title" style="text-align: left;">{{ 'texto_514' | translate }}</h4>
            <div style="float: right; margin-top: -30px;"></div>
          </div>
        </div>
        <div class="panel-body">
          <mat-tab-group animationDuration="0ms">
            <!--SELECCION-->
            <mat-tab label="SELECCIÓN">      
              <div class="row">
                <div class="col-xl">
                  <form [formGroup]="myForm" style="margin-top: 25px;">
                    <mat-form-field style=" margin-right: 30px;">
                    <!--<mat-label>{{ 'Tabla' | translate }}</mat-label>-->
                    <mat-select  [(ngModel)]="selectedValuetabla" [formControl]="tabla"  (selectionChange)="onChangeTabla($event)" (click)="cargarTablas($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let tab of tablas" [value]="tab.id" >
                      {{tab.n_tabla}}
                      </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <input type="hidden" class="form-control" [formControl]="n_tabla_value" id="n_tabla_value" [(ngModel)]="n_tablaF" value="{{n_tablaF}}">  	
                    <mat-form-field>
                      <!--<mat-label>{{ 'Atributos' | translate }}</mat-label>-->
                      <mat-select [(ngModel)]="selectedValueatributo" [formControl]="atributo" (selectionChange)="onChangeAtributo($event)" (click)="cargarAtributos(selectedValuetabla)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option value="*">*</mat-option>
                      <mat-option *ngFor="let atri of atributos" [value]="atri.id_atributo" >
                        {{atri.n_atributo}}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input type="hidden" style="margin-left: 5px" class="form-control" [formControl]="n_atributo_value" id="n_atributo_value" [(ngModel)]="n_atributoF" value="{{n_atributoF}}">  	
                  </form>		
                </div>
                <div class="col-lg-auto">
                  <button (click)="Anadir()" type="button" rel="tooltip" title="Añadir" class="" style="background:#33ff00;color:#FFFFFF;border:none;margin-top: 25px;">
                    <i class="material-icons">done</i>
                  </button> 		
                </div>
              </div>
              <div class="row">
                <div class="col-xl">
                  <table class="table">
                    <thead>
                    <th>{{ 'texto_503' | translate }}</th>
                    <th>{{ 'texto_504' | translate }}</th>  
                    <th>{{ 'texto_505' | translate }}</th>
                    <th>{{ 'texto_506' | translate }}</th>
                    <th>{{ 'texto_507' | translate }}</th>
                    <th></th>
                    </thead>
                    <tbody>
                    <tr *ngFor = "let column of columnas_qry">
                      <td>{{n_tablaF}}</td>
                      <td>{{column.valor1}}</td>
                      <td><form [formGroup]="myForm5">
                      <mat-form-field>
                        <input matInput [name]="alias" id="alias" [(ngModel)]="aliasF"  (change)="onChangeAlias($event,column.valor1)">
                      </mat-form-field>
                      </form></td>
              
                      <td>
                      <form [formGroup]="myForm6">
                      <select class="form-control" id="selectatri" [(ngModel)]="selectedTotal" (change)="onChangeTotal($event,column.valor1)">
                      <option value="">--</option>
                      <option value="COUNT">Cuenta</option>
                      <option value="MAX">Máximo</option>
                      <option value="MIN">Mínimo</option>
                      <option value="SUM">Suma</option>
                      <option value="MED">Media</option>
                      </select>
                    </form> 
                      </td>
                      <td class="td-actions text-right" style="width:90px;">    
                      <button (click)="deletecampo_select(column.valor1)" type="button" rel="tooltip" title="Remove" class="" style="background:RED;color:#FFFFFF;border:none;">
                      <i class="material-icons">clear</i>
                      </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>  		
                </div>
              </div>                                                                      
            </mat-tab>
            <mat-tab label="FILTRO">
              <form [formGroup]="myForm2" style="margin-top: 25px;">
                <div class="row">
                  <div class="col-lg">
                    <mat-form-field style="margin-right: 50px;">
                      <input matInput [formControl]="n_tabla_value2" id="n_tabla_value2" [(ngModel)]="n_tablaF" value="{{n_tablaF}}">
                    </mat-form-field>
                  </div>
                  <div class="col-lg">
                    <mat-form-field>
                      <!--<mat-label>{{ 'Atributos' | translate }}</mat-label>-->
                      <mat-select [(ngModel)]="selectedValueatributo2" [formControl]="atributo2" (selectionChange)="onChangeAtributo($event)" (click)="cargarAtributos(selectedValuetabla)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let atri of atributos" [value]="atri.n_atributo" >
                      {{atri.n_atributo}}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button (click)="Anadir2(selectedValueatributo2)" type="button" rel="tooltip" title="Añadir" class="" style="background:#33ff00;color:#FFFFFF;border:none;margin-left: 15px;">
                      <i class="material-icons">done</i>
                    </button> 						
                  </div>
                  <div class="col-lg">
                    <mat-form-field>
                      <input matInput [formControl]="valor_filtro2" id="valor_filtro2" [(ngModel)]="valor_filtroF" value="{{valor_filtroF}}">
                    </mat-form-field>
                    <button (click)="Anadir2(valor_filtroF)" type="button" rel="tooltip" title="Añadir" class="" style="background:#33ff00;color:#FFFFFF;border:none;margin-left: 15px;">
                      <i class="material-icons">done</i>
                    </button>   						
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl" style="background-color: #9191914d;color: #000000!important; text-align: justify;">
                    <span id="sentencia_where">{{sentencia_where}}</span>
                  </div>
                  <div class="col-xs">	
                    <button (click)="deletecampo_where()" type="button" rel="tooltip" title="Añadir" class="" style="background: red;color:#FFFFFF;border:none;">
                      <i class="material-icons">clear</i>
                    </button>  		
                  </div>
                  <div class="col-lg-auto">
                    <div class="example-button-row">
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('(')">(</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2(')')">)</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('NULL')">NULL</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('NOT NULL')">NO NULL</button>
                    <button mat-raised-button color="primary" (click)="Anadir2('CURDATE()')">HOY</button>
                    </div>
                    <div class="example-button-row">
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('+')">+</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('-')">-</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('*')">*</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('/')">/</button>
                    <button mat-raised-button color="primary" (click)="Anadir2('NOT')">NO</button>
                    </div>   
                    <div class="example-button-row">
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('<')"><</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('>')">></button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('<>')"><></button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('>=')">>=</button>
                    <button mat-raised-button color="primary" (click)="Anadir2('<=')"><=</button>
                    </div>    
                    <div class="example-button-row">
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('=')">=</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('LIKE')">LIKE</button>
                    <button style="margin-right: 5px;" mat-raised-button color="primary" (click)="Anadir2('AND')">Y</button>
                    <button mat-raised-button color="primary" (click)="Anadir2('OR')">O</button>
                    </div>  	
                  </div>	
                </div>
                </form>                
            </mat-tab>
            <mat-tab label="GRUPOS">
              <div class="row">
                <div class="col-xl">
                  <form [formGroup]="myForm4" style="margin-top: 25px;">
                    <mat-form-field style="margin-right: 50px;">
                      <input matInput [formControl]="n_tabla_value4" id="n_tabla_value4" [(ngModel)]="n_tablaF" value="{{n_tablaF}}">  
                    </mat-form-field>
                    <mat-form-field>
                      <!--<mat-label>{{ 'Atributos' | translate }}</mat-label>-->
                      <mat-select [(ngModel)]="selectedValueatributo4" [formControl]="atributo4" (selectionChange)="onChangeAtributo($event)" (click)="cargarAtributos(selectedValuetabla)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let atri of atributos" [value]="atri.n_atributo" >
                        {{atri.n_atributo}}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>	
                  </form>	
                </div>
                <div class="col-lg-auto">                  			
                  <button (click)="Anadir4()" type="button" rel="tooltip" title="Añadir" class="" style="background:#33ff00;color:#FFFFFF;border:none;margin-top: 25px;">
                    <i class="material-icons">done</i>
                  </button>  
              </div>
              </div>
              <div class="row">
                <div class="col-xl">
				        <table class="table">
                  <thead>
                    <th>{{ 'texto_503' | translate }}</th>
                    <th>{{ 'texto_504' | translate }}</th>
                    <th>{{ 'texto_507' | translate }}</th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let column_group of columnas_group">
                      <td>{{n_tablaF}}</td>
                      <td>{{column_group}}</td>
                      <td class="td-actions text-right" style="width:90px;">    
                        <button (click)="deletecampo_group(column_group)" type="button" rel="tooltip" title="Remove" class="" style="background:red;color:#FFFFFF;border:none;">
                        <i class="material-icons">clear</i>
                        </button>
                      </td>
                    </tr>
                  </tbody> 
                </table> 				
                </div>
              </div>              
            </mat-tab>
            <mat-tab label="ORDEN">
              <div class="row">
                <div class="col-xl">
				          <form [formGroup]="myForm3" style="margin-top: 25px;">
                    <mat-form-field style="margin-right: 50px;">
                      <input matInput [formControl]="n_tabla_value3" id="n_tabla_value3" [(ngModel)]="n_tablaF" value="{{n_tablaF}}">
                    </mat-form-field>
                    <mat-form-field style="margin-right: 50px;"> 
                      <!--<mat-label>{{ 'Atributos' | translate }}</mat-label>-->
                      <mat-select [(ngModel)]="selectedValueatributo3" [formControl]="atributo3" (selectionChange)="onChangeAtributo($event)" (click)="cargarAtributos(selectedValuetabla)" >
                        <mat-option [value]="">--</mat-option>
                        <mat-option *ngFor="let atri of atributos" [value]="atri.n_atributo" >
                          {{atri.n_atributo}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <!--<mat-label>{{ 'Orden' | translate }}</mat-label>-->
                      <mat-select [(ngModel)]="selectedValueoperador3" [formControl]="operador3" (selectionChange)="onChangeOrden($event)">
                        <mat-option value="">--</mat-option>
                        <mat-option value="ASC">Ascendente</mat-option>
                        <mat-option value="DESC">Descendente</mat-option>
                      </mat-select>
                    </mat-form-field>				  
                  </form>				
                </div>
                <div class="col-lg-auto">
                <button (click)="Anadir3()" type="button" rel="tooltip" title="Añadir" class="" style="background:#33ff00;color:#FFFFFF;border:none;margin-top: 25px;">
                  <i class="material-icons">done</i>
                </button> 
              </div>
              </div>
              <div class="row">
                <div class="col-xl">
				        <table class="table">
                  <thead>
                    <th>{{ 'texto_503' | translate }}</th>
                    <th>{{ 'texto_504' | translate }}</th>
                    <th>{{ 'texto_508' | translate }}</th>
                    <th>{{ 'texto_507' | translate }}</th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let column_order of columnas_order">
                      <td>{{n_tablaF}}</td>
                      <td>{{column_order.valor1}}</td>
                      <td>{{column_order.valor2}}</td>
                      <td class="td-actions text-right" style="width:90px;">    
                        <button (click)="deletecampo_order(column_order)" type="button" rel="tooltip" title="Remove" class="" style="background:RED;color:#FFFFFF;border:none;">
                          <i class="material-icons">clear</i>
                        </button>
                      </td>
                    </tr>
                  </tbody> 
                </table> 				
                </div>
              </div>	                         
            </mat-tab>
            <!--<mat-tab label="AVANZADO">
              <div class="row">
                <div class="col-xs">
                  <button (click)="abrirConsulta()" type="button" rel="tooltip" title="Abrir" class="" style="background: #cdcdcd;color: black;border: none;font-size: 18px;width: -webkit-fill-available;text-align: -webkit-auto;">
                    <i class="material-icons" style="vertical-align: text-bottom;">folder</i>&nbsp;&nbsp;Abrir Consulta
                  </button>
                </div>
                <div class="col-xs">
                  <button (click)="guardarConsulta()" type="button" rel="tooltip" title="Guardar" class="" style="background: #cdcdcd;color: black;border: none;font-size: 18px;width: -webkit-fill-available;text-align: -webkit-auto;">
                    <i class="material-icons" style="vertical-align: text-bottom;">save</i>&nbsp;&nbsp;Guardar Consulta
                  </button>   
                </div>
              </div>
              <div class="row">
                <div class="col-xl" style="background-color: #9191914d;color: #000000!important; text-align: justify;">
                  {{datosconsulta}}				
                </div>
              </div>              
            </mat-tab>-->
          </mat-tab-group>
          <button (click)="Ejecutar()" style="float:right;" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_515' | translate }}</button>
        </div>        
      </div>

      <div class="card">
        <div class="card-header card-header-primary" style="height: 60px;">
          <h4 class="card-title ">{{ 'texto_509' | translate }}</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive" *ngIf="datosQuery">
            <i matTooltip="{{'texto_510' | translate }}" class="fa fa-4x fa-file-excel-o" (click)="exportExcel()" style="float: left;margin-top: 3px;margin-right: 10px;"></i>          
            <!--<i matTooltip="{{'texto_511' | translate }}" class="fa fa-4x fa-file-pdf-o" (click)="savePDF()" style="float: left;margin-top: 3px;margin-right: 10px;"></i>          
            --><i matTooltip="{{'texto_512' | translate }}" class="fa fa-4x fa-file-text-o" (click)="exportCsv(datosQuery)" style="float: left;margin-top: 3px;margin-right: 10px;"></i>  
            <mat-paginator [length]="datosQuery.length"  [pageSize]="page_size" [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)"></mat-paginator>
            <table class="table" id="excel-denuncia-table">
              <thead>   
                  <th *ngFor = "let column of this.columnas_rdo">
                    {{column}}
                  </th>                    
              </thead>
              <tbody>
                <tr *ngFor = "let item of datosQuery | paginate:page_size:page_number">
                  <td *ngFor = "let column of this.columnas_rdo">
                   {{item[column]}}
                  </td>
                </tr>                  
              </tbody>
            </table>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>