<div [hidden]="!IsHiddenLanding">
  <app-nav-fixed [IsHiddenLanding]="IsHiddenLanding"></app-nav-fixed>
</div>

<header [hidden]="IsHiddenLanding">
  <div style="z-index:90 !important;background-color:white; ">
    <nav style="z-index:90 !important;padding-left: 5% !important;padding-right: 5% !important; margin-bottom: 0px !important;"
      class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"><img src="assets/img/logos/logo.png" width="160px"
        style="margin-left: -10px;">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#da-navbarNav"
        aria-controls="da-navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span
          class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse text-uppercase" id="da-navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #000000!important;" routerLink="/inicio">{{
              'texto_1' | translate }}</a></li>
          <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #000000!important;" (click)="navigateToSection('services')">{{
              'texto_2' | translate }}</a></li>
          <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #000000!important;" (click)="navigateToSection('denuncia')">{{
              'texto_3' | translate }}</a></li>
          <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #000000!important;" (click)="navigateToSection('support')">{{
              'texto_4' | translate }}</a></li>
          <li class="nav-item">
            <a class="nav-link smooth-scroll" style="color: #000000!important;"><app-translation></app-translation></a>
          </li>
        </ul>
      </div>

      <!-- Botón login-->
      <a class="smooth-scroll btn mt-4"
        style="background-color:var(--secondary-color-bg);color:var(--color-txt-btn);font-weight: bold; border-radius: 0.75rem; margin-top: 2px !important;"
        (click)="isOn(false)" routerLink="/login"><mdb-icon fas icon="user" class="prefix"></mdb-icon> {{ 'texto_252' |
        translate }}
      </a>
      <!-- <app-login></app-login> -->
    </nav>
  </div>
  <div id="inicio" class="da-home-page-text" data-aos="fade-right" data-aos-duration="1000">
    <div class="container">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <h3 class="h5 mt-3" style="color:var(--color-txt-titulo);font-weight: bold;">{{ 'texto_5'
          | translate }}
        </h3>
        <h2 class="display-3" style="color:var(--color-txt-titulo);font-weight: bold;">{{ 'texto_6'
          | translate
          }}</h2>
        <a class="smooth-scroll btn mt-4"
          style="background-color:var(--main-color-bg);color:var(--color-txt-btn);font-weight: bold; border-radius: 0.75rem;"
          (click)="isOn(false)" routerLink="/enviar-denuncia/datos-empresa">{{ 'texto_7' | translate
          }}</a>
        <!-- <a class="smooth-scroll btn mt-4"
          style="background-color:var(--main-color-bg);color:var(--color-txt-btn);font-weight: bold;"
          (click)="isOn(false)" routerLink="/grabar-denuncia">{{ 'texto_691' |
          translate }}</a> -->
        <a class="smooth-scroll btn mt-4"
          style="background-color:var(--secondary-color-bg);color:var(--color-txt-btn);font-weight: bold; border-radius: 0.75rem;"
          (click)="isOn(false)" routerLink="/ver-denuncia/consultar-denuncia">{{ 'texto_8' |
          translate }}</a>
      </div>
    </div>
  </div>
</header>

<div [hidden]="IsHiddenLanding">
  <app-informacion-principal></app-informacion-principal>
  <footer class="da-section" style="background-color:var(--main-color-bg)">
    <div class="container text-white">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <p style="font-size:12px; text-align: center;">&copy; {{dateYear}}
            Becompliance. {{ 'texto_42' | translate
            }}</p>
        </div>
        <!-- <div class="col-md-4 col-lg-4">
                    <p style="font-size:12px; text-align: center;"><a (click)="isOn(false)"
                            routerLink="/politica-cookies">{{ 'texto_687' | translate }}</a></p>
                </div> -->
        <div class="col-md-6 col-lg-6">
          <p style="font-size:12px; text-align: center;">CanalDenuncia.app v
            {{version}}</p>
        </div>
      </div>

    </div>
  </footer>
  <section [hidden]="IsHidden" style="top: 24px;position: absolute;width: 100%;width: 75%;z-index: 1;right: 9%;">
    <div class="container">
      <div class="board" style="width: 100% !important;">
        <!-- Navigation Area (Circular Tabs) -->
        <msw-navbar></msw-navbar>
        <!-- End Navigation Area (Circular Tabs) -->

        <!-- Content Area -->
        <div class="tab-content">
          <!-- Routed view  -->
          <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark"
            data-dismiss="modal" (click)="hideModal()">X</button>
          <router-outlet></router-outlet>

        </div>
        <!-- End Content Area -->
      </div>

      <!-- For Debugging: show our formData as it is being typed -->

    </div>
  </section>

</div>
