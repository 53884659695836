import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { InformacionPrincipalComponent } from './informacion-principal/informacion-principal.component';
import { HeaderComponent } from './header/header.component';
import { WorkflowService }    from './workflow/workflow.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormDataService }    from 'src/app/data/formData.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { AdminComponent } from './admin/admin.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './shared/icons/icons.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SociedadesComponent } from './sociedades/sociedades.component';
import { MatTableModule } from  '@angular/material/table';
import { AddSociedadComponent } from './add-sociedad/add-sociedad.component';
import { DataTablesModule } from 'angular-datatables';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormularioDenunciaComponent } from './formulario-denuncia/formulario-denuncia.component';

import { AddWitnessComponent } from './add-witness/add-witness.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { AnadirArchivoReceptorComponent } from './anadir-archivo-receptor/anadir-archivo-receptor.component';
import { EditarDenunciaComponent } from './editar-denuncia/editar-denuncia.component';
import { AddInvestigatorComponent } from './add-investigator/add-investigator.component';
import { DesestimarDenunciaComponent } from './desestimar-denuncia/desestimar-denuncia.component';
import { NgxWigModule } from 'ngx-wig';
import { ValidarDenunciaComponent } from './validar-denuncia/validar-denuncia.component';
import { EnviarDenunciaReceptorComponent } from './enviar-receptor/enviar-receptor.component';
import { AsignarReceptorComponent } from './asignar-receptor/asignar-receptor.component';
import { EnviarDenunciaInvestigadorComponent } from './enviar-denuncia-investigador/enviar-denuncia-investigador.component';
import { AsignarMedidaCautelarComponent } from './asignar-medida-cautelar/asignar-medida-cautelar.component';
import { EnviarInformeComponent } from './enviar-informe/enviar-informe.component';
import { AsignarDecisorComponent } from './asignar-decisor/asignar-decisor.component';
import { EnviarResolucionComponent } from './enviar-resolucion/enviar-resolucion.component';
import { AnadirAccionComponent } from './anadir-accion/anadir-accion.component';
import { EnviarinformeDenuncianteComponent } from './enviar-informe-denunciante/enviar-informe-denunciante.component';
import { ArchivarComponent } from './archivar/archivar.component';import { MatTooltipModule } from '@angular/material/tooltip';
import { AnadirArchivoComponent } from './anadir-archivo/anadir-archivo.component';
import { NavFixedComponent } from './nav-fixed/nav-fixed.component';
import { UserIdleModule } from 'angular-user-idle';
import { SessionExpirationAlert, SessionInteruptService } from 'session-expiration-alert';
import { AppSessionInteruptService } from './services/app-session-interupt.service';
import { DragDropDirective } from './drag-drop.directive';
import { AddWitnessAdminComponent } from './add-witness-admin/add-witness-admin.component';
import { EditarTodasDenunciasComponent } from './editar-todas-denuncias/editar-todas-denuncias.component';
import { PaginatePipe } from './pipes/paginate.pipe';
import { CustomMatPaginatorIntl } from './paginator-es';
import { ErrorInterceptor } from './auth-1/error.interceptor';
import { JwtInterceptor } from './auth-1/jwt.interceptor';
import { appInitializer } from './auth-1/app.initializer';
import { AuthService } from './shared/services/auth.service';
import { fakeBackendProvider } from './auth-1/fake-backend';
import { JwtModule } from '@auth0/angular-jwt';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { HtmltoplaintextPipe } from './pipes/htmltoplaintext.pipe';

import {ClipboardModule} from '@angular/cdk/clipboard';
import { AddAudioRecorderComponent } from './add-audio-recorder/add-audio-recorder.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ChangePhaseComplaintComponent } from './complaint-components/change-phase-complaint/change-phase-complaint.component';
import { ChangeTypeComplaintComponent } from './complaint-components/change-type-complaint/change-type-complaint.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { DenunciasModule } from './ver-denuncias/denuncias.module';
import { EnviarDenunciaModule } from './enviar-denuncia/enviar-denuncia.module';
//import { TerminadaDenunciaComponent } from './enviar-denuncia/components/terminada-denuncia/terminada-denuncia.component';

@NgModule({
  declarations: [
    AppComponent,
    PoliticaCookiesComponent,
    InformacionPrincipalComponent,
    HeaderComponent,
    AdminComponent,
    TableListComponent,
    TypographyComponent,
    //IconsComponent,
    NotificationsComponent,
    UpgradeComponent,
    SociedadesComponent,
    AddSociedadComponent,
    FormularioDenunciaComponent,
    AnadirArchivoReceptorComponent,
    EditarDenunciaComponent,
    AddInvestigatorComponent,
    DesestimarDenunciaComponent,
    ValidarDenunciaComponent,
    EnviarDenunciaInvestigadorComponent,
    EnviarDenunciaReceptorComponent,
    AsignarReceptorComponent,
    AsignarMedidaCautelarComponent,
    EnviarInformeComponent,
    AsignarDecisorComponent,
    EnviarResolucionComponent,
    AnadirAccionComponent,
    EnviarinformeDenuncianteComponent,
    ArchivarComponent,
    AnadirArchivoComponent,
    NavFixedComponent,
    DragDropDirective,
    AddWitnessAdminComponent,
    EditarTodasDenunciasComponent,
    HtmltoplaintextPipe,
    AddAudioRecorderComponent,
    ChangePhaseComplaintComponent,
    ChangeTypeComplaintComponent,

  ],
  imports: [
    MatAutocompleteModule,
    NgbModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatTableModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    MatPaginatorModule,
    BrowserModule,
    DataTablesModule,
    FormsModule,
    NgxWigModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    AuthRoutingModule,
    SharedModule,
    EnviarDenunciaModule,
    DenunciasModule,
    SessionExpirationAlert.forRoot({ totalMinutes: 30 }),   // UserIdleModule.forRoot({idle: 1, timeout: 30, ping: 20}),
    MDBBootstrapModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [ HttpClient ]
      }
    }),
    BrowserAnimationsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: function  tokenGetter() { return  localStorage.getItem('access_token'); },
    //     //allowedDomains: ['localhost:3000'],
    //     //disallowedRoutes: ['http://localhost:3000/auth/login']
    //   }
    // }),

  ],
  exports: [
    MatMomentDateModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [    MatDatepickerModule,
    { provide: FormDataService, useClass: FormDataService },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: WorkflowService, useClass: WorkflowService },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: SessionInteruptService, useClass: AppSessionInteruptService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, //CATCH EXPIRE TOKEN FOR REFRESH TOKEN
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, //CATCH ERROR 401 FOR REFRESH THE PAGE
    //{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }, //CATCH HTTPS REQUEST FOR SHOW SPINNER LOADED
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ldku8sZAAAAANZFlrofaO_GHQQbxH99Y2nT_D-C' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
