<section style="position: absolute;width: 100%;z-index: 1;" class="image-container"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();">X</button>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ 'texto_717'
              | translate }}</li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->
      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #denuncianteForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h4 class="head text-center">{{title | translate }}</h4>
            <label class="control-label" [hidden]="IsAnonimo" style="text-align: center;"><strong>{{ 'texto_144' |
                translate }}.</strong></label>
            <label class="control-label" style="text-align: center;">
              <!--Para poder acceder a los datos......-->
              {{ 'texto_145' | translate }}
              <br>
            </label>
            <br>
            <label class="control-label" style="text-transform: uppercase; text-align: center">
              <!--Guárdelo en un lugar se......-->
              {{'texto_146' | translate}}
            </label>
            <div class="container">
              <div class="row new-box">
                <div class="col m-5">
                  <p class="text-description">
                    PIN: <strong>{{pin_code}} </strong>&nbsp;&nbsp;&nbsp;
                    <button [cdkCopyToClipboard]="value_pin_code" type="button" rel="tooltip" title="Copy to Clipboard"
                      class="copy-button" style="background: none; color: black; border: none;"
                      (cdkCopyToClipboardCopied)="onCopied()">
                      <i class="material-icons" style="margin-top: 1px;">content_copy</i>
                    </button>
                  </p>
                  <p class="text-description">
                    Identificador: <strong>{{idDenuncia}}</strong>&nbsp;&nbsp;&nbsp;
                    <button [cdkCopyToClipboard]="value_identificador" type="button" rel="tooltip"
                      title="Copy to Clipboard" class="copy-button"
                      style="background: none; color: black; border: none;" (cdkCopyToClipboardCopied)="onCopied()">
                      <i class="material-icons" style="margin-top: 1px;">content_copy</i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div class="container">
              <button routerLink="/ver-denuncia/consultar-denuncia"
                style="color:#fff" mdbBtn rounded="true" class="btn-next" mdbWavesEffect>{{ 'texto_147' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
