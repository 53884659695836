<div class style="z-index:90 !important;background-color:white;">
  <nav
    style="z-index:90 !important;padding-left: 5% !important;padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important; "
    class="navbar navbar-expand-lg navbar-dark bg-transparent px-0">
    <a href="#inicio" class="button-link">
      <img src="assets/img/logos/logo.png" width="160px"
        style="margin-left: -10px;">
      </a>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link smooth-scroll"
          style="color: #000000!important;"><app-translation></app-translation></a>
      </li>
    </ul>
  </nav>
</div>

<section>

  <div class="close">
    <a href="/inicio" class="close-btn"><i
        class="bi bi-x-square-fill"></i></a>
  </div>

  <div class="breadcrum-div">
    <nav aria-label="breadcrumb" class="nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
            translate }}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_147'
          | translate }}</li>
      </ol>
    </nav>
  </div>

  <div class="container">
    <div class="left-side">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <h3 class="h5 mt-3"
          style="color:var(--color-bg-seccion1);font-weight: bold;">{{
          'texto_5'
          | translate }}
        </h3>
        <h2 class="display-3"
          style="color:var(--color-bg-seccion1);font-weight: bold;">{{
          'texto_98'
          | translate
          }}</h2>
      </div>
    </div>
    <div class="right-side">
      <div class="form-container">
        <img class="logo" src="assets/img/logos/logo.png">

        <!-- <h1 class="title-form">{{ 'texto_360' | translate }}</h1> -->
        <p class="description-form">{{ 'texto_361' | translate }}</p>
        <div class="md-form mb-5">
          <mdb-icon fas icon="envelope" class="prefix grey-text"
            style="display: contents;"></mdb-icon>
          <label class="label-form" for="formId">* {{ 'texto_362' | translate
            }}:</label>
          <input type="text" id="formId" class="form-control"
            [formControl]="formId"
            mdbInput mdbValidate>
          <mdb-error style="margin-left: 80%;top:-12px !important;"
            *ngIf="formId.invalid && (formId.dirty || formId.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="margin-left: 80%;top:-12px !important;"
            *ngIf="formId.valid && (formId.dirty || formId.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <mdb-icon fas icon="lock" class="prefix grey-text"
            style="display: contents;"></mdb-icon>
          <label class="label-form" for="formPin">* {{ 'Pin' | translate
            }}:</label>
          <input type="password" id="formPin" class="form-control input-form"
            [formControl]="formPin"
            mdbInput mdbValidate>
          <mdb-error style="top:200px !important;margin-left:0"
            *ngIf="formPin.invalid && (formPin.dirty || formPin.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="top:200px !important;margin-left:0"
            *ngIf="formPin.valid && (formPin.dirty || formPin.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="d-flex justify-content-center">
          <button (click)="enviar()" style="color:#fff" mdbBtn rounded="true"
            class="btn" mdbWavesEffect>{{ 'texto_41' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</section>
