<div class="page-content">
        <div>
    <div class="da-section da-work" id="services" style="background-color:var(--color-bg-seccion1)!important;">
    <div class="container  margin-container">
      <div class="h3 pb-3 text-center" data-aos="" style="color:var(--color-txt-seccion-1)">{{ 'texto_9' | translate }}</div>
      <div class="row">
        <div class="col-md-6 col-lg-6" style="color:var(--color-txt-seccion-1);text-align: justify;">
          <p>{{ 'texto_10' | translate }}</p>
          <p>{{ 'texto_11' | translate }}</p>
          <p>{{ 'texto_12' | translate }}</p>
          <p>{{'texto_336' | translate }}</p>
          <p>{{'texto_337' | translate }}</p>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <img width="100%" src="assets/img/inicio/inicio-2.jpg">
            </div>
          </div>
            <div class="row" style="padding-top: 20px;">
                <div class="col-md-4 col-lg-4"  >
                  <div style="text-align:center;" *ngIf="boton1 == '1'" >
                    <a target="_blank" [href]=docboton1>
                        <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles" >
                          {{ 'texto_467' | translate }}
                        </button>
                    </a>
                </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div style="text-align:center;" *ngIf="boton2 == '1'">
                    <a target="_blank" [href]=docboton2>
                        <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles">
                          {{ 'texto_487' | translate }}
                        </button>
                    </a>
                </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div style="text-align:center;" *ngIf="boton3 == '1'">
                    <a target="_blank" [href]=docboton3>
                        <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles">
                          {{ 'texto_489' | translate }}
                        </button>
                    </a>
                </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    </div>
    <div class="da-section" id="denuncia" style="background-color:var(--color-bg-seccion2)">
    <div class="da-services">
      <div class="container text-center  margin-container">
        <div class="h3 pb-5 text-center" style="color:var(--color-txt-seccion2)" data-aos="">{{ 'texto_13' | translate }}</div>
        <div class="row">
          <div style="color:var(--color-txt-seccion2);text-align: justify;" class="col-md-6 col-lg-6">
              <p class="font-weight-bold">{{ 'texto_14' | translate }}</p>
                 <ul>
                   <li>{{ 'texto_15' | translate }}</li>
                   <li>{{ 'texto_16' | translate }}</li>
                   <li>{{ 'texto_17' | translate }}</li>
                   <li>{{ 'texto_18' | translate }}</li>
                   <li>{{ 'texto_19' | translate }}</li>
                 </ul>
                 <p>{{'texto_20' | translate }}</p>
                 <p><b>{{'texto_21' | translate }}: </b>{{'texto_22'| translate }}</p>
          </div>
          <div class="col-md-6 col-lg-6" style="color:var(--color-txt-seccion2); text-align: justify;">
            <p class="font-weight-bold" style="text-align: justify;">{{ 'texto_23' | translate }}</p>
            <p style="text-align: justify;">{{ 'texto_24' | translate }}</p>
            <ul style="color:var(--color-txt-seccion2);text-align: justify;margin-left: -40px;">
              <ol> {{ 'texto_25' | translate }}</ol>
              <ol> {{ 'texto_26' | translate }}</ol>
            </ul>
            <p style="color:var(--color-txt-seccion2); text-align: justify;"><b><u>{{ 'texto_27' | translate }} </u></b>{{ 'texto_28' | translate }}</p>

          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="da-projects" id="support" style="background-color:var(--color-bg-seccion3)">
        <div class="container text-center margin-container">
            <div class="h3 pb-5 text-center " style="color:var(--color-txt-seccion3);" data-aos="">{{ 'texto_4' | translate }}</div>
              <div class="row">
                  <div class="col-md-6 col-lg-6">
                      <img width="100%" src="assets/img/inicio/inicio-3.jpg">
                    </div>
                <div class="col-md-6 col-lg-6" style="color:var(--color-txt-seccion3);text-align:center">
                    <p style="text-align: justify;">{{ 'texto_399' | translate }}</p>
                    <!--<p>{{ 'texto_31' | translate }}</p>-->
                    <div style="text-align: justify;">
                      <p>{{ 'texto_32' | translate }}<br><a href="{{ 'texto_33' | translate }}">{{ 'texto_33' | translate }}</a></p>
                      <p>{{ 'texto_34' | translate }}</p>
                    </div>
                        <div class="da-contact-message">
                            <div class="row">
                              <div class="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <input [formControl]="firstName"  class="mr-3 form-control" type="text" name="firstName" placeholder="{{ 'texto_35' | translate }}"/>
                              </div>
                              <div class="col-md-6 col-lg-6 col-sm-12 mb-3">
                                <input [formControl]="lastName" class="form-control" type="text" name="lastName" placeholder="{{ 'texto_36' | translate }}"/>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <input [formControl]="subject" class="form-control" type="text" name="subject" placeholder="{{ 'texto_37' | translate }}"/>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <input [formControl]="replyTo" class="form-control" type="email" name="replyTo" placeholder="{{ 'texto_38' | translate }}" />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <textarea mdbValidate [formControl]="message" class="form-control" name="message" placeholder="{{ 'texto_39' | translate }}" rows="4" required="required"></textarea>
                                <!--<mdb-error
                                  *ngIf="message.invalid && (message.dirty || message.touched)">{{ 'texto_40' | translate }}
                                </mdb-error>-->
                                <p><small style="font-size: 10px;">*{{ 'texto_483' | translate }}</small></p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <button (click)="enviarEmail()" class="btn btn-primary" >{{ 'texto_41' | translate }}</button>
                              </div>
                            </div>
                        </div>
                </div>
              </div>
            </div>
    </div>

      </div>

