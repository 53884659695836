<!-- <app-spinner></app-spinner> -->
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{sociedad}}</h4>
                </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="pageSize" [pageIndex]="currentPage - 1" [length]="totalItems" (page)="onPageChange($event)">
            </mat-paginator>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <!-- <th>ID. {{ 'texto_67' | translate }}</th>
                            <th>N. {{ 'texto_67' | translate }}</th>
                            <th>{{ 'texto_95' | translate }}</th> -->
                            <th>ID. {{ 'texto_672' | translate }}</th>
                            <th>N.  {{ 'texto_672' | translate }}</th>
                            <th>N.  {{ 'texto_67' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of centros">
                            <td>{{ item.id_centro }}</td>
                            <td>{{ item.n_centro }}</td>
                            <td>{{ item.n_empresa }}</td>
                            <td class="td-actions text-right" style="width:90px;">
                              <button (click)="showCenter(item)" type="button" rel="tooltip" title="Edit Center" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button>

                              <button (click)="deleteCenter(item.id_centro)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"  style="width: 100% !important;margin: 0 auto;" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                          <!--Content-->
                          <div class="modal-content" style="width: 100%  !important;">
                              <div class="modal-body mx-3" style="width: 100% !important;">
                                  <div class="card-header card-header-info">
                                    <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_686' | translate }}</h5>
                                </div>
                                <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click)="hideModal()">X</button> 
                                  <!--INPUT ID CENTRO-->
                                  <div class="md-form mb-5">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalId"
                                           mdbInput mdbValidate value={{id_centro}} readonly>
                                    <!-- <label for="orangeForm-email">ID. {{ 'texto_67' | translate }}</label> -->
                                    <label for="orangeForm-email">ID. {{ 'texto_672' | translate }}</label>
                                    <mdb-error
                                      *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success
                                      *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                    </mdb-success>
                                  </div>
                                  <!--INPUT NOMBRE CENTRO-->
                                  <div class="md-form mb-5">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalName"
                                           mdbInput maxlength="255" mdbValidate value={{n_centro}}>
                                    <!-- <label for="orangeForm-name">{{ 'texto_64' | translate }}</label> -->
                                    <label for="orangeForm-name">N. {{ 'texto_672' | translate }}</label>
                                    <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                      {{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                      {{ 'texto_62' | translate }}
                                    </mdb-success>
                                  </div>
                                  <!--INPUT EMPRESAS-->
                                  <div class="md-form mb-5">
                                      <!-- <label for="orangeForm-empresa">ID. {{ 'texto_67' | translate }}</label><br> -->
                                      <label for="orangeForm-empresa">ID. {{ 'texto_67' | translate }}</label><br>
                                      <mat-form-field>
                                          <mat-select [formControl]="signupFormModalidEmpresa">
                                              <mat-option *ngFor="let empresa of empresas" [value]="empresa.id_empresa">
                                              {{empresa.n_empresa}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                      <mdb-error *ngIf="signupFormModalidEmpresa.invalid && (signupFormModalidEmpresa.dirty || signupFormModalidEmpresa.touched)">
                                        {{ 'texto_61' | translate }}
                                      </mdb-error>
                                      <mdb-success *ngIf="signupFormModalidEmpresa.valid && (signupFormModalidEmpresa.dirty || signupFormModalidEmpresa.touched)">
                                        {{ 'texto_62' | translate }}
                                      </mdb-success>
                                   <br>
                                  </div>
                                  <!--INPUT COMENTARIOS-->
                                   <div class="md-form">
                                      <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                      <textarea maxlength="4000" type="text" id="form107Modal" class="md-textarea form-control" rows="5" mdbInput [formControl]="contactFormModalMessage" value={{comentarios}}></textarea>
                                      <label for="form107Modal">{{ 'texto_46' | translate }}</label>
                                    </div>
                                </div>
                              <div class="text-center mt-4">
                                <button (click)="updateCenter(id_centro)"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                                </button>
                              </div>
                      
                          </div>
                          
                       </div>
                       
                    
                  </div>
                  <app-add-center></app-add-center>

                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
