<app-spinner></app-spinner>
<section style="position: absolute;width: 100%;z-index: 1;" class="image-container"
    style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board">
            <a href="/" (click)="navigateToNewPage()">
                <button type="button" style="z-index:100;float: right;" class="btn btn-dark">X</button>
            </a>

            <!--Breadcrumbs-->
            <div class="breadcrum-div">
                <nav aria-label="breadcrumb" class="nav">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                                translate }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_717'
                            | translate }}</li>
                    </ol>
                </nav>
            </div>
            <!--End Breadcrumbs-->
            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #finalizarForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{title | translate }}</h4>
                        <div class='row'>
                            <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                <div class="row">
                                    <div class='col-xs-12 col-sm-12'>
                                        <label class="control-label" for="comentario">{{ 'texto_160' | translate }}
                                        </label>
                                        <textarea maxlength="4000" class="form-control input-md" #comentario="ngModel"
                                            id="comentario" name="comentario" type="text"
                                            placeholder="{{ 'texto_95' | translate }}"
                                            [(ngModel)]="finalizar.comentario" rows="8"></textarea>

                                    </div>
                                </div>
                                <!--Términos y Condiciones-->
                                <div class="row">
                                    <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                                        <mat-checkbox required id="terminos" name="terminos"
                                            [(ngModel)]="finalizar.terminos">{{ 'texto_161' | translate }}
                                            <a *ngIf="terminos2 == '1' ; else elseBlock" target="_blank" [href]=url> {{
                                                'texto_162' | translate }}</a>
                                            <ng-template #elseBlock>
                                                <a target="_blank" [href]=documento> {{ 'texto_162' | translate }}</a>
                                            </ng-template>
                                            {{ 'texto_163' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <!--Protección de Datos-->
                                <!-- <div class="row">
                                    <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                                        <mat-checkbox required id="proteccion" name="proteccion" [(ngModel)]="finalizar.proteccionDatos">{{ 'texto_161' | translate }}
                                            <a *ngIf="proteccionDatos == '1' ; else elseBlock"   target="_blank" [href]=documentoProteccionDatos> {{ 'texto_672' | translate }}</a>
                                            <ng-template #elseBlock>
                                                <a  target="_blank" [href]=documentoProteccionDatos> {{ 'texto_672' | translate }}</a>
                                            </ng-template>
                                            {{ 'texto_163' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div> -->
                                <div class="form-group text-center" style="margin-top: 30px;">
                                    <button (click)="goToPrevious(finalizarForm)" style="color:#fff" mdbBtn
                                        rounded="true" class="btn-back" mdbWavesEffect><span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left">
                                        </span>{{ 'texto_142' | translate }}
                                    </button>

                                    <button [disabled]="!finalizarForm.valid" (click)="goToNext(finalizarForm)"
                                        style="color:#fff" mdbBtn rounded="true" class="btn-next" mdbWavesEffect>{{
                                        'texto_164' | translate }} <span style="margin-left:10px;"
                                            class="glyphicon glyphicon-arrow-right">
                                        </span>
                                    </button>
                                </div>
                                <input type="hidden" id="finaliced" name="finaliced" value="">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>