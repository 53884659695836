import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GestionUsuariosService } from 'src/app/dashboard/services/gestion-usuarios.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})

export class AddUserComponent implements OnInit {
  validatingForm: FormGroup;

  constructor(private usuariosService: GestionUsuariosService, private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      signupFormModalId: new FormControl('', [
        Validators.required,
      ]),
      signupFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalSurname: new FormControl('', Validators.required),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get signupFormModalSurname() {
    return this.validatingForm.get('signupFormModalSurname');
  }

  get signupFormModalEmail() {
    return this.validatingForm.get('signupFormModalEmail');
  }

  addUser(){
    let key = this.makeKey(10);

    let $clau = false;
    let clausulas_correo = localStorage.getItem("clausulas_correo");
    if(clausulas_correo == '1'){
      $clau = true;
    }
    
    this.usuariosService.addUser(this.signupFormModalId.value, this.signupFormModalEmail.value, this.signupFormModalName.value, this.signupFormModalSurname.value,this.auth.getSociedad(), key).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_311'), this.translate.instant('texto_312'));
          let url = environment.base + "/#/editar-denuncia/";
          let message = this.translate.instant('texto_576') + " "  + this.signupFormModalName.value + " "  + this.signupFormModalSurname.value  + this.translate.instant('texto_577')+ " " + this.signupFormModalId.value + " " + this.translate.instant('texto_578')+ " " + key + " " + this.translate.instant('texto_579');
          this.apiService.sendEmail(this.signupFormModalEmail.value,this.translate.instant('texto_580'), message,url,$clau, this.translate.instant('texto_580')).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
              }else{
                this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
              }
          });
        }else{
          this.toastr.error(this.translate.instant('texto_313'), this.translate.instant('texto_312'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/usuarios']);
        });
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_313'), this.translate.instant('texto_312'));
    });
  }

  makeKey(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
