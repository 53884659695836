import { Component, ElementRef, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NavigationService } from '../shared/services/navigation.Service';

@Component({
  selector: 'app-informacion-principal',
  templateUrl: './informacion-principal.component.html',
  styleUrls: ['./informacion-principal.component.css']
})
export class InformacionPrincipalComponent implements OnInit {
  validatingForm: FormGroup;
  version: string;
  boton1: string;
  docboton1: string;
  boton2: string;
  docboton2: string;
  boton3: string;
  docboton3: string;
  selectedLanguage: string = 'default'; // Establece 'default' o el c�digo del idioma predeterminado

  euskeraStyles = {
    'background-color': 'var(--main-color-bg)',
    'border-color': 'var(--main-color-bg)',
    'color': 'var(--color-txt-botones)',
    'text-transform': 'uppercase',
    'display': 'inline-block',
    'font-weight': '400',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'border': '1px solid transparent',
    'padding': '0.375rem 0.75rem',
    'font-size': '1.3rem',
    'line-height': '1.5',
    'border-radius': '0.25rem'
  };
  normalStyles = {
    'background-color': 'var(--main-color-bg)',
    'border-color': 'var(--main-color-bg)',
    'color': 'var(--color-txt-botones)',
    'text-transform': 'uppercase',
    'display': 'inline-block',
    'font-weight': '400',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'border': '1px solid transparent',
    'padding': '0.375rem 0.75rem',
    'font-size': '1.5rem',
    'line-height': '1.5',
    'border-radius': '0.25rem'
  };

  constructor(
    private apiService: ApiService, 
    private router: Router, 
    private translate: TranslateService, 
    private toastr: ToastrService,
    private navigationService: NavigationService,
    private  elementRef: ElementRef
    ) { }

  ngOnInit() {
    this.animacionHref();
    this.version = localStorage.getItem("version");
    this.boton1 = localStorage.getItem("boton1");
    this.docboton1 = localStorage.getItem("docboton1");
    this.boton2 = localStorage.getItem("boton2");
    this.docboton2 = localStorage.getItem("docboton2");
    this.boton3 = localStorage.getItem("boton3");
    this.docboton3 = localStorage.getItem("docboton3");
    //console.log(this.boton1);
    //console.log(this.docboton1);

    this.validatingForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      subject: new FormControl('', []),
      replyTo: new FormControl('', []),
      message: new FormControl('', [ Validators.required]),
    });

    this.translate.onLangChange.subscribe((event) => {
      this.selectedLanguage = event.lang;
    });
  }

  get firstName() {
    return this.validatingForm.get('firstName');
  }

  get lastName() {
    return this.validatingForm.get('lastName');
  }

  get subject() {
    return this.validatingForm.get('subject');
  }

  get replyTo() {
    return this.validatingForm.get('replyTo');
  }

  get message() {
    return this.validatingForm.get('message');
  }

  enviarEmail(){
    if(this.message.value == ""){
      this.toastr.error(this.translate.instant('texto_253'), this.translate.instant('texto_243'));
    }else{
      this.apiService.sendEmailSupport(this.firstName.value, this.lastName.value, this.subject.value, this.replyTo.value, this.message.value).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_255'), this.translate.instant('texto_257'));
          }else{
            this.toastr.error(this.translate.instant('texto_256'), this.translate.instant('texto_257'));
          }
          window.location.reload();
      });
    }

  }

  //method for navigating between sections of the home page
  animacionHref(){
    this.navigationService.navigateToSection$.subscribe(sectionId => {
      const sectionElement = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

}
