import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Personal } from '../models/formData.model';

@Injectable({
  providedIn: 'root',
})
export class FinalizarDenunciaService {
  PHP_API_SERVER = environment.baseUrl; //"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private auth: AuthService
  ) {}

  createComplaint(
    id_sociedad: string,
    id_centro: string,
    id_tp_denuncia: string,
    denunciante_nombre: string,
    denunciante_apellidos: string,
    denunciante_dni: string,
    denunciante_correo: string,
    denunciante_telefono: string,
    fecha_incidente: string,
    id_tp_relacion: string,
    denuncia_desc: string,
    id_fase: string,
    id_empresa: string,
    comentario: string,
    file_name: string,
    receptor: string,
    motivo: string,
    receptorinicial: string,
    finalizada: string,
    testigos: Personal[],


  ) {


    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/crearDenuncia.php`,
      {
        id_sociedad: id_sociedad,
        id_tp_denuncia: id_tp_denuncia,
        denunciante_nombre: denunciante_nombre,
        denunciante_apellidos: denunciante_apellidos,
        denunciante_dni: denunciante_dni,
        denunciante_correo: denunciante_correo,
        denunciante_telefono: denunciante_telefono,
        fecha_incidente: fecha_incidente,
        id_tp_relacion: id_tp_relacion,
        denuncia_desc: denuncia_desc,
        id_fase: id_fase,
        id_empresa: id_empresa,
        comentario: comentario,
        file_name: file_name,
        receptor: receptor,
        motivo: motivo,
        id_centro: id_centro,
        receptorinicial: receptorinicial,
        finalizada: finalizada,
        testigos,


      },
      options
    );

  }

  updateParamComplaintById(id_denuncia: string) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/modificarCampoDenunciaByIdDenuncia.php`,
      {
        id_denuncia: id_denuncia,
      },
      options
    );
  }

  sendEmail(
    id_user: string,
    subject: string,
    message: string,
    boton: string,
    clausulas: boolean,
    titulo: string
  ) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/enviarEmail.php`,
      {
        id_user: id_user,
        subject: subject,
        message: message,
        boton: boton,
        clausulas: clausulas,
        titulo: titulo,
      },
      options
    );
  }
  searchLayerByIdSociety(id_abogado: string, id_sociedad: string) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/buscarAbogadoById.php`,
      {
        id_abogado: id_abogado,
        id_sociedad: id_sociedad,
      },
      options
    );
  }

  searchRelationTypeById(
    id_tp_relacion: string,
    id_sociedad: string,
    idioma: string
  ) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/buscarTipoRelacionById.php`,
      {
        id_tp_relacion: id_tp_relacion,
        id_sociedad: id_sociedad,
        idioma: idioma,
      },
      options
    );
  }

  searchComplaintByPin(pin_code: string, idioma: string) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/buscarDenunciaByPin.php`,
      {
        pin_code: pin_code,
        idioma: idioma,
      },
      options
    );
  }

  searchReceptorByIdComplaint(id_denuncia: string, seguro: boolean) {
    const headers = { Authorization: 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };

    return this.httpClient.post(
      `${this.PHP_API_SERVER}/api/buscarReceptorByIdDenuncia.php`,
      {
        id_denuncia: id_denuncia,
        seguro: seguro,
      },
      options
    );
  }

  uploadFile(formData: FormData){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`,formData, options)

  }
}
