import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatosDenuncia } from 'src/app/ver-denuncias/models/datosDenuncia';
import { ConsultarDenunciaService } from 'src/app/ver-denuncias/services/consultar-denuncia.service';


@Component({
  selector: 'app-consultar-denuncia',
  templateUrl: './consultar-denuncia.component.html',
  styleUrls: ['./consultar-denuncia.component.css']
})
export class ConsultarDenunciaComponent implements OnInit{
  validatingForm: FormGroup;
  denuncia: DatosDenuncia[];


  constructor(
    private consultarDenunciaService: ConsultarDenunciaService,
    private router: Router, 
    private auth: AuthService, 
    private toastr: ToastrService, 
    private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      formPin: new FormControl('', Validators.required),
      formId: new FormControl('', Validators.required)
    });
  }


  get formPin() {
    return this.validatingForm.get('formPin');
  }

  get formId() {
    return this.validatingForm.get('formId');
  }

  enviar(){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    if(this.formPin.value == "" || this.formId.value == ""){
      this.toastr.error(this.translate.instant('texto_288'), this.translate.instant('texto_243'));
    }else{
      this.consultarDenunciaService.searchComplaintByPinId(this.formPin.value, this.formId.value,language).subscribe((denuncia: DatosDenuncia[])=>{
        this.denuncia = denuncia;
        if(denuncia.length == 0){
          this.toastr.error(this.translate.instant('texto_189'), this.translate.instant('texto_190'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/ver-denuncia/consultar-denuncia']);
          });
        }else{
          localStorage.setItem("verdenuncia", "");
          this.router.navigate(['ver-denuncia/', this.formId.value]);
          // Cambiar el estado de isLoggedInComplaint a true una vez que se hayan validado los datos del formulario
          this.consultarDenunciaService.isLoggedInComplaint = true;
        }
      });
    }
  }



  inicio(){
   this.auth.logoutSimple();
    /*window.location.reload();*/
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

}
